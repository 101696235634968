import { Box, Typography } from '@mui/material';
import { TradesType } from '../types';
import { isInfinite } from 'utils/GenericFunctions';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useState } from 'react';
import { SYMBOL } from '../utils';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { useTheme } from '@mui/material/styles';
import TradeExecutionModal from './TradeExecutionModal';
import { GetInstrumentsResult } from 'types/Instrument';

type TradeDetailsCardProps = {
  trade_type: string;
  symbol: string;
  trade_details: TradesType;
  view: string;
  expiry: string;
  instruments: GetInstrumentsResult;
};

const TradeDetailsCard = ({
  trade_type,
  symbol,
  trade_details,
  view,
  expiry,
  instruments,
}: TradeDetailsCardProps) => {
  const [open, setOpen] = useState(false);
  const isMdDown = useGetMediaQueryDown('md');
  const [lots, setLots] = useState(1);
  const trade =
    trade_type.split('_')[0][0].toUpperCase() +
    trade_type.split('_')[0].slice(1);
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      className="p-main-spacing"
      sx={{
        bgcolor: theme.palette.common.white,
        flex: '1 1 calc(25% - 16px)',
        mx: 2,
        my: isMdDown ? 1 : 0,
        borderRadius: 2,
        boxShadow: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'} my={2}>
        <Typography variant="body1">{trade}</Typography>
        <Box
          sx={{
            display: 'flex',
            p: 0,
            cursor: 'pointer',
            alignItems: 'center',
            color: theme.component.tradeIdeas.mainSelectedCard,
          }}
          onClick={() => setOpen(true)}
        >
          <Typography variant="subtitle2">Details</Typography>
          <KeyboardDoubleArrowRight sx={{ fontSize: '17px' }} />
        </Box>
      </Box>
      <TradeRiskLine trade_type={trade} />
      <Typography variant="body2" mt={2} mb={'4px'} color={'grey.600'}>
        Choose this if you think
      </Typography>
      <Typography variant="body1" mb={1}>
        {view === 'bullish'
          ? `${SYMBOL[`${symbol}`]} will not go down, and stay above ${
              trade_details.payoff.breakevens[0]
            }`
          : view === 'bearish'
          ? `${SYMBOL[`${symbol}`]} is expected to remain below ${
              trade_details.payoff.breakevens[0]
            } and not rise further.
        `
          : view === 'sideways'
          ? `${SYMBOL[`${symbol}`]} is expected to maintain a range between ${
              trade_details.payoff.breakevens[0]
            } and ${trade_details.payoff.breakevens[1]}.`
          : view === 'directional'
          ? `${
              SYMBOL[`${symbol}`]
            } is expected to remain below or above at this ${
              trade_details.payoff.breakevens[0]
            } points.`
          : ''}
      </Typography>
      <Box display={'flex'} flex={1} my={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            borderRadius: 1,
            backgroundColor: 'grey.100',
            mr: 1,
            py: 1,
          }}
        >
          <Typography variant="body1">Max Profit</Typography>
          <Typography variant="body2" color={'success.main'}>
            {!isInfinite(trade_details.payoff.max_gain * lots)
              ? trade_details.payoff.max_gain * lots
              : 'Unlimited'}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            borderRadius: 1,
            backgroundColor: 'grey.100',
            ml: 1,
            py: 1,
          }}
        >
          <Typography variant="body1">Max Loss</Typography>
          <Typography variant="body2" color={'error.main'}>
            {!isInfinite(trade_details.payoff.max_loss * lots)
              ? trade_details.payoff.max_loss * lots
              : '-Unlimited'}
          </Typography>
        </Box>
      </Box>
      {lots > 1 && (
        <Typography variant="body2" mb={2}>
          {`This profit loss is for ${lots} Lots`}
        </Typography>
      )}
      {open && (
        <TradeExecutionModal
          open={open}
          handleClose={handleClose}
          tradeData={trade_details}
          trade_type={trade}
          handleLotsize={setLots}
          lotSize={lots}
          symbol={symbol}
          expiry={expiry}
          instruments={instruments}
        />
      )}
    </Box>
  );
};

export default TradeDetailsCard;

const TradeRiskLine = ({ trade_type }: { trade_type: string }) => {
  switch (trade_type) {
    case 'Defensive':
      return <DefensiveLine />;
    case 'Balanced':
      return <BalancedLine />;
    case 'Aggressive':
      return <AggressiveLine />;
    default:
      return <AlphaLine />;
  }
};

const DefensiveLine = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: `6px`,
        borderRadius: '15px',
        backgroundColor: theme.component.tradeIdeas.greenColor,
      }}
    ></Box>
  );
};
const BalancedLine = () => {
  return (
    <Box
      sx={{
        height: `6px`,
        borderRadius: '15px',
        backgroundImage: 'linear-gradient(90deg, #018749 1.24%, #e3da08 28.8%)',
      }}
    ></Box>
  );
};
const AggressiveLine = () => {
  return (
    <Box
      sx={{
        height: `6px`,
        borderRadius: '15px',
        backgroundImage:
          'linear-gradient(90deg, #039855 1.24%, #e3da08 17.44%, #c30909 42.7%)',
      }}
    ></Box>
  );
};
const AlphaLine = () => {
  return (
    <Box
      sx={{
        height: `6px`,
        borderRadius: '15px',
        backgroundImage:
          'linear-gradient(90deg, #018749 14.24%, #e3da08 70.8%)',
      }}
    ></Box>
  );
};
