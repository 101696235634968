import {
  CRUDEOIL,
  BANK_NIFTY_SYMBOL,
  FIN_NIFTY_SYMBOL,
  NIFTY_SYMBOL,
  MID_CAP_NIFTY_SYMBOL,
  QQQ,
  SPY,
  SPX,
  XSP,
  SENSEX,
  BANKEX,
} from 'constants/common';
import { formatDateString } from 'utils/GenericFunctions';
import {
  CheckFieldRenderer,
  DropdownFieldRenderer,
  InputFieldRenderer,
  DateRenderer,
  TimeRenderer,
  ButtonGroupRenderer,
  SelectWithDoubleInputRenderer,
  SelectWithSingleInputRenderer,
  ToggleButtonGroupRenderer,
  RadioButtonsRenderer,
  MultipleSelectAndInputRenderer,
} from './Renderers';
import EquityStockSymbols from './data/EquityStockSymbols.json';
import USAStockSymbols from './data/USAStockSymbols..json';

import { DropdownFieldValue, FieldParam, OptionLegParam } from './types';
import { equityJsonData } from 'app/components/Bots/StrategyBuilder/OptionStrategyBuilderContent';

export const SYMBOL_RANGES: Record<string, number[]> = new Proxy(
  {
    [FIN_NIFTY_SYMBOL]: [-10, 10],
    [NIFTY_SYMBOL]: [-20, 20],
    [BANK_NIFTY_SYMBOL]: [-20, 20],
    [MID_CAP_NIFTY_SYMBOL]: [-20, 20],
    [CRUDEOIL]: [-5, 5],
  },
  {
    get: (target, key) =>
      typeof key === 'string' && target[key] !== undefined
        ? target[key]
        : [-10, 10],
  },
);

export function createContractSelectionOptions(
  symbol: string,
  contractSelectionType: string,
  right: string,
) {
  // const factor = symbol === NIFTY_SYMBOL ? 1 : 2;
  const renderText = right === 'call' ? ['ITM', 'OTM'] : ['OTM', 'ITM'];
  const range = SYMBOL_RANGES[symbol];
  const retData: DropdownFieldValue[] = [];
  if (contractSelectionType === 'strike') {
    for (let i = range[0]; i <= range[1]; i++) {
      const operator = i < 0 ? renderText[0] : renderText[1];
      retData.push({
        key: i.toString(),
        label: i !== 0 ? `${operator}${Math.abs(i)}` : 'ATM',
      });
    }
  } else {
    for (let i = range[1]; i >= range[0]; i--) {
      const operator = i < 0 ? '' : '+';
      retData.push({
        key: (i * 0.5).toString(),
        label: i !== 0 ? `ATM${operator}${i * 0.5}%` : 'ATM',
      });
    }
  }
  return retData;
}

export function createDaysToExpireSelectionOptions(
  expiryOn: string,
  isExit: boolean,
  entryDays: string,
) {
  const retData: DropdownFieldValue[] = [];
  const maxExpiryDays = expiryOn === 'weekly' ? 4 : 24;
  for (let i = 0; i < (isExit ? parseInt(entryDays) : maxExpiryDays) + 1; i++) {
    retData.push({
      key: i.toString(),
      label: `${i} Days`,
    });
  }
  return retData;
}

export function createRRTypeSelectionOptions(
  isReEntryReExection: boolean,
  isEntryOnAnotherLeg?: boolean,
) {
  const retData: DropdownFieldValue[] = [
    {
      key: 'ctc',
      label: 'Cost To Cost',
    },
  ];
  if (isReEntryReExection) {
    Array.prototype.push.apply(retData, [
      {
        key: 'ctc_reverse',
        label: 'Cost To Cost ↩',
      },
      {
        key: 'immediate',
        label: 'Immediate',
      },
      {
        key: 'immediate_reverse',
        label: 'Immediate ↩',
      },
      {
        key: 'momentum',
        label: 'Momentum',
      },
      {
        key: 'momentum_reverse',
        label: 'Momentum ↩',
      },
      {
        key: 'ctc_underlying',
        label: 'Cost To Cost (UL)',
      },
      {
        key: 'ctc_underlying_reverse',
        label: 'Cost To Cost (UL) ↩',
      },
      {
        key: 'delay',
        label: 'Delay',
      },
      {
        key: 'delay_reverse',
        label: 'Delay ↩',
      },
    ]);
  } else {
    retData.push({
      key: 'Remove',
      label: 'Remove',
    });
  }
  if (isEntryOnAnotherLeg) {
    retData.push({
      key: 'entryonanotherleg',
      label: 'Leg',
    });
  }

  return retData;
}

export function createTradingSymbolSelectionOptions(enabledSymbols: {
  crudeOil?: boolean;
  stockSymbols?: boolean;
  bankex?: boolean;
  QQQ?: boolean;
  SPY?: boolean;
  SPX?: boolean;
  XSP?: boolean;
  usaStockSymbols?: boolean;
  symbolFromBackend?: boolean;
  botBuilderType?: string;
}) {
  const retData: DropdownFieldValue[] = [
    // {
    //   key: NIFTY_SYMBOL,
    //   label: 'Nifty',
    // },
    // {
    //   key: BANK_NIFTY_SYMBOL,
    //   label: 'Bank Nifty',
    // },
    // {
    //   key: FIN_NIFTY_SYMBOL,
    //   label: 'Fin Nifty',
    // },
  ];
  if (enabledSymbols && enabledSymbols.botBuilderType == 'option') {
    if (!enabledSymbols.QQQ) {
      retData.push(
        {
          key: NIFTY_SYMBOL,
          label: 'Nifty',
        },
        {
          key: BANK_NIFTY_SYMBOL,
          label: 'Bank Nifty',
        },
        {
          key: FIN_NIFTY_SYMBOL,
          label: 'Fin Nifty',
        },
        {
          key: MID_CAP_NIFTY_SYMBOL,
          label: 'Mid Cap Nifty',
        },
        {
          key: SENSEX,
          label: 'Sensex',
        },
      );
      if (enabledSymbols.bankex) {
        retData.push({
          key: BANKEX,
          label: 'Bankex',
        });
      }
      if (enabledSymbols.crudeOil) {
        retData.push({
          key: CRUDEOIL,
          label: 'Crude Oil',
        });
      }
      if (enabledSymbols.stockSymbols) {
        retData.push(
          {
            key: 'divider',
            label: 'divider',
          },
          ...(equityJsonData.value.length && enabledSymbols.symbolFromBackend
            ? equityJsonData.value
            : EquityStockSymbols),
        );
      }
    } else {
      retData.push(
        {
          key: QQQ,
          label: 'QQQ',
        },
        {
          key: SPY,
          label: 'SPY',
        },
        {
          key: SPX,
          label: 'SPX',
        },
        {
          key: XSP,
          label: 'XSP',
        },
      );
      if (enabledSymbols.usaStockSymbols) {
        retData.push(
          {
            key: 'divider',
            label: 'divider',
          },
          ...(equityJsonData.value.length && enabledSymbols.symbolFromBackend
            ? equityJsonData.value
            : USAStockSymbols),
        );
      }
    }
  } else {
    retData.push(
      {
        key: 'divider',
        label: 'divider',
      },
      ...(equityJsonData.value.length && enabledSymbols.symbolFromBackend
        ? equityJsonData.value
        : USAStockSymbols),
    );
  }

  return retData;
}

export const ExpiryTypeValueOptions = [
  {
    key: 'weekly_0',
    label: 'Current Week',
  },
  {
    key: 'weekly_1',
    label: 'Next Week',
  },
  {
    key: 'monthly_0',
    label: 'Current Month',
  },
  {
    key: 'monthly_1',
    label: 'Next Month',
  },
];
export const contractTypeWithDeltaValues = [
  {
    key: 'price',
    label: 'Price',
  },
  {
    key: 'strike',
    label: 'ATM Point',
  },
  {
    key: 'percentage',
    label: 'Percentage',
  },
  {
    key: 'price_greater_then',
    label: 'Price Greater Than',
  },
  {
    key: 'price_less_then',
    label: 'Price Less Than',
  },
  {
    key: 'price_range',
    label: 'Price Range',
  },
];
export const buttonGroupValues = [
  {
    key: 'intraday',
    label: 'Intraday',
  },
  {
    key: 'positional',
    label: 'Positional',
  },
];
export function createButtonGroupOptions(
  isEnabled?: boolean,
  botBuilderType?: string | undefined,
) {
  const retData = [...buttonGroupValues];
  if (!botBuilderType?.includes('crypto')) {
    retData.push({
      key: 'stbt_btst',
      label: 'STBT/BTST',
    });
    if (isEnabled) {
      // retData = contractTypeWithDeltaValues.filter(
      //   value => !value.key.startsWith('delta'),
      // );
      retData.push({
        key: 'continuous',
        label: 'Continuous (Beta)',
      });
    }
  }

  return retData;
}
export function checkFeatureFlagForContractType(isEnabled?: boolean) {
  const retData = [...contractTypeWithDeltaValues];
  if (isEnabled)
    // retData = contractTypeWithDeltaValues.filter(
    //   value => !value.key.startsWith('delta'),
    // );
    retData.push(
      {
        key: 'delta',
        label: 'Delta',
      },
      {
        key: 'delta_greater_then',
        label: 'Delta Greater Then',
      },
      {
        key: 'delta_less_then',
        label: 'Delta Less Then',
      },
      {
        key: 'delta_range',
        label: 'Delta Range',
      },
    );

  return retData;
}
export function createReEntryLegOptions(
  legs?: OptionLegParam[],
  legName?: string,
) {
  const ReEntryLegOptions = [
    {
      key: 'create',
      label: 'Create New',
    },
  ];
  const retData = [...ReEntryLegOptions];
  if (legs) {
    legs.map(leg => {
      if (leg['is_reentry_leg'] && legName != leg['name']) {
        retData.push({
          key: `${leg.name}`,
          label: `${leg.name} (${leg.legEnabled})`,
        });
      }
    });
  }
  return retData;
}
export const stopLossAndTargetProfitValues = [
  {
    key: 'points',
    label: 'Points',
  },
  {
    key: 'percentage',
    label: 'Percentage',
  },
  {
    key: 'absolute',
    label: 'Absolute',
  },
];
export function checkDeltaStrikeEnabled(
  isEnabled?: boolean,
  isOptionLeg?: boolean,
) {
  const retData = [...stopLossAndTargetProfitValues];
  if (isOptionLeg) {
    retData.push(
      {
        key: 'ul_points',
        label: 'UL Points',
      },
      {
        key: 'ul_percentage',
        label: 'UL Percentage',
      },
    );
    if (isEnabled) {
      // retData = contractTypeWithDeltaValues.filter(
      //   value => !value.key.startsWith('delta'),
      // );
      retData.push(
        {
          key: 'delta_points_up',
          label: 'Delta Points▲',
        },
        {
          key: 'delta_points_down',
          label: 'delta Points▼',
        },
        {
          key: 'delta_percentage_up',
          label: 'Delta Percentage▲',
        },
        {
          key: 'delta_percentage_down',
          label: 'Delta Percentage▼',
        },
        {
          key: 'strike_points_up',
          label: 'Strike Points▲',
        },
        {
          key: 'strike_points_down',
          label: 'Strike Points▼',
        },
        {
          key: 'strike_percentage_up',
          label: 'Strike Percentage▲',
        },
        {
          key: 'strike_percentage_down',
          label: 'Strike Percentage▼',
        },
        {
          key: 'entered_strike',
          label: 'Contract strike price',
        },
      );
    }
  }
  retData.push({
    key: 'Remove',
    label: 'Remove',
  });

  return retData;
}
export function createContractExpityTypeSelection(
  expiryType?: string,
  enableDte?: boolean,
  isFutureLegEnabled?: string,
) {
  let retData = [...ExpiryTypeValueOptions];
  if (isFutureLegEnabled == 'futureleg' || isFutureLegEnabled == 'equityleg') {
    retData = ExpiryTypeValueOptions.filter(
      item => !item.key.includes('weekly'),
    );
  }
  if (expiryType === 'monthly')
    retData = ExpiryTypeValueOptions.filter(item =>
      item.key.includes('monthly'),
    );
  if (enableDte && isFutureLegEnabled !== 'futureleg') {
    for (let i = 0; i <= 4; i++) {
      retData.push({ key: `dte_${i}`, label: `${i} DTE` });
    }
  }
  return retData;
}

export const DirectionValueOptions = [
  {
    key: 'long',
    label: 'BUY',
  },
  {
    key: 'short',
    label: 'SELL',
  },
];

export const OptionLegFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_contract_selection_type',
    name: 'Contract Type',
    renderer: DropdownFieldRenderer,
    group: 0,
    tooltip: '',
    controls: [
      {
        key: 'contract_selection_type',
        type: 'dropdown',
        defaultValue: 'strike',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_contract_type',
        values: checkFeatureFlagForContractType(),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_direction',
    name: 'Direction',
    renderer: DropdownFieldRenderer,
    group: 0,
    width: 128,
    controls: [
      {
        key: 'direction',
        type: 'dropdown',
        defaultValue: 'long',
        isRequired: true,
        enabled: true,
        values: DirectionValueOptions,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    width: 148,
    parentKey: 'parent_contract_selection_value',
    type: 'input',
    name: 'Closest Premium',
    renderer: InputFieldRenderer,
    group: 0,
    hide: true,
    controls: [
      {
        key: 'contract_selection_value',
        type: 'input',
        defaultValue: 100,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_contract_selection_value',
    name: 'Value',
    type: 'select',
    renderer: DropdownFieldRenderer,
    group: 0,
    width: 128,
    hide: false,
    controls: [
      {
        key: 'contract_selection_value',
        type: 'dropdown',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
        values: createContractSelectionOptions(NIFTY_SYMBOL, 'strike', 'call'),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_combine_contract_selection_price_range',
    name: 'Price Range',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 0,
    hide: false,
    controls: [
      {
        key: 'contract_selection_price_range_start',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
      {
        key: 'contract_selection_price_range_end',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_combine_contract_selection_delta_range',
    name: 'Delta Range',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 0,
    hide: false,
    controls: [
      {
        key: 'contract_selection_delta_range_start',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
      {
        key: 'contract_selection_delta_range_end',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_right',
    name: 'Type',
    renderer: DropdownFieldRenderer,
    group: 0,
    controls: [
      {
        key: 'right',
        type: 'dropdown',
        defaultValue: 'call',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'call',
            label: 'CALL',
          },
          {
            key: 'put',
            label: 'PUT',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_type',
    name: 'Type',
    renderer: InputFieldRenderer,
    group: 0,
    width: 128,
    hide: true,
    controls: [
      {
        key: 'type',
        type: 'input',
        defaultValue: 'option',
        isRequired: true,
        enabled: true,
      },
    ],
  },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_order_product_type',
  //   renderer: ToggleButtonGroupRenderer,
  //   group: 0,
  //   renderingType: 'horizontal',
  //   addClass: 'order_product_type_parent',
  //   myStyle: {
  //     root: {
  //       'div.order_product_type_parent': {
  //         marginLeft: '20px',
  //       },
  //     },
  //     small: {
  //       'div.order_product_type_parent': {
  //         marginLeft: '0',
  //       },
  //     },
  //     medium: {
  //       'div.order_product_type_parent': {
  //         marginLeft: '15px',
  //       },
  //     },
  //   },
  //   tooltip: 'buttonGroupRenederer',
  //   controls: [
  //     {
  //       key: 'order_product_type',
  //       type: 'togglebuttongroup',
  //       defaultValue: 'undefined',
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         {
  //           key: 'mis',
  //           label: 'Intraday',
  //         },
  //         //// Do not remove
  //         // {
  //         //   key: 'cnc',
  //         //   label: 'Positional',
  //         // },
  //         {
  //           key: 'cnc',
  //           label: 'STBT/BTST',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    validation: {
      status: true,
      error: '',
    },
    addClass: 'lots-parent',
    parentKey: 'parent_lots',
    name: 'Lots',
    renderer: InputFieldRenderer,
    group: 0,
    width: 128,
    controls: [
      {
        key: 'lots',
        type: 'input',
        defaultValue: 1,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_combine_expiry_type_value',
    name: 'Expiry',
    renderer: DropdownFieldRenderer,
    group: 0,
    width: 192,
    addClass: 'parent-ml-auto',
    controls: [
      {
        key: 'combine_expiry_type_value',
        type: 'dropdown',
        defaultValue: 'weekly_0',
        isRequired: true,
        enabled: true,
        values: createContractExpityTypeSelection(),
      },
    ],
  },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_days_to_expiration',
  //   name: 'DTE',
  //   renderer: DropdownFieldRenderer,
  //   group: 0,
  //   hide: true,
  //   width: 192,
  //   addClass: 'parent-ml-auto',
  //   controls: [
  //     {
  //       key: 'days_to_expiration',
  //       type: 'dropdown',
  //       defaultValue: '0',
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         { key: '0', label: '0DTE' },
  //         // { key: '1', label: '1DTE' },
  //         // { key: '2', label: '2DTE' },
  //         // { key: '3', label: '3DTE' },
  //       ],
  //     },
  //   ],
  // },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_expiry_type',
    name: 'Expiry Type',
    renderer: DropdownFieldRenderer,
    group: 0,
    controls: [
      {
        key: 'expiry_type',
        type: 'dropdown',
        defaultValue: 'weekly',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'weekly',
            label: 'weekly',
          },
          {
            key: 'monthly',
            label: 'monthly',
          },
          { key: 'dte', label: 'DTE' },
        ],
      },
    ],
    hide: true,
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_expiry_value',
    name: 'Expiry Value',
    renderer: DropdownFieldRenderer,
    group: 1,
    controls: [
      {
        key: 'expiry_value',
        type: 'dropdown',
        defaultValue: '',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '0',
            label: 'Current',
          },
          {
            key: '1',
            label: 'Next',
          },
        ],
      },
    ],
    hide: true,
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_momentum',
    name: 'Momentum',
    renderer: SelectWithSingleInputRenderer,
    group: 1,
    uniqueKey: 'leg_momentum',
    isAddButtonActive: true,
    controls: [
      {
        key: 'momentum_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_momentum',
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Percentage',
          },
          {
            key: 'points_up',
            label: 'Points Up',
          },
          {
            key: 'points_down',
            label: 'Points Down',
          },
          {
            key: 'percentage_up',
            label: 'Percentage Up',
          },
          {
            key: 'percentage_down',
            label: 'Percentage Down',
          },
          {
            key: 'underlying_points_up',
            label: 'UL Points Up',
          },
          {
            key: 'underlying_points_down',
            label: 'UL Points Down',
          },
          {
            key: 'underlying_percentage_up',
            label: 'UL Percentage Up',
          },
          {
            key: 'underlying_percentage_down',
            label: 'UL Percentage Down',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'momentum_value',
        type: 'input',
        defaultValue: 0,
        isRequired: false,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_Target_Profit',
    name: 'Target Profit',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    uniqueKey: 'leg_target_profit',
    group: 1,
    controls: [
      {
        key: 'target_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        width: 300,
        values: checkDeltaStrikeEnabled(),
      },
      {
        key: 'target_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        tooltip: 'leg_target_profit',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_stop_loss',
    name: 'Stop Loss',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 1,
    uniqueKey: 'leg_stop_loss',
    controls: [
      {
        key: 'sl_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: checkDeltaStrikeEnabled(),
      },
      {
        key: 'sl_value',
        type: 'input',
        defaultValue: '',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_stop_loss',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_trail_stop_loss',
    name: 'Trail Stop Loss',
    renderer: SelectWithDoubleInputRenderer,
    group: 1,
    uniqueKey: 'leg_trail_stop_loss',
    isAddButtonActive: true,
    hide: true,
    controls: [
      {
        key: 'trailing_sl_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        tooltip: 'double input renderer',
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Percentage',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'trailing_sl_trigger_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        helpText: 'Stop Loss Movement',
      },
      {
        key: 'trailing_sl_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        helpText: 'Leg Movement',
        tooltip: 'leg_trail_stop_loss',
      },
      {
        key: 'trail_sl',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   type: 'selectwithinput',
  //   parentKey: 'parent_re_entry',
  //   name: 'Re-Entry',
  //   renderer: SelectWithSingleInputRenderer,
  //   group: 1,
  //   isAddButtonActive: true,
  //   controls: [
  //     {
  //       key: 'reentry_type',
  //       type: 'dropdown',
  //       defaultValue: 'none',
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         {
  //           key: 'ctc',
  //           label: 'Cost To Cost',
  //         },
  //         {
  //           key: 'Remove',
  //           label: 'Remove',
  //         },
  //       ],
  //     },
  //     {
  //       key: 'reentry_count',
  //       type: 'input',
  //       defaultValue: 0,
  //       isRequired: true,
  //       enabled: true,
  //     },
  //   ],
  // },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_rr_type',
    name: 'Re-Entry',
    renderer: MultipleSelectAndInputRenderer,
    isAddButtonActive: true,
    group: 1,
    uniqueKey: 'leg_re_entry',
    controls: [
      {
        key: 'rr_type',
        type: 'dropdown',
        defaultValue: 'reentry',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'reentry',
            label: 'Re-Entry',
          },
          {
            key: 'reexecution',
            label: 'Re-Execution',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'reentry_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: createRRTypeSelectionOptions(false),
      },
      {
        key: 'reentry_count',
        type: 'input',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
      },
      {
        key: 'delay_seconds',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: false,
        helpText: 'sec',
      },
      {
        key: 'lazy_legs',
        type: 'multi_select',
        width: 100,
        defaultValue: 0,
        isRequired: true,
        values: createReEntryLegOptions(),
        enabled: false,
      },
      {
        key: 'reentry_reexecution_dependency',
        type: 'dropdown',
        defaultValue: 'sl',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_re_entry',
        values: [
          {
            key: 'sl',
            label: 'SL',
          },
          {
            key: 'tg',
            label: 'TG',
          },
          {
            key: 'sltg',
            label: 'SL/TG',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_range_breakout',
    name: 'Range Breakout',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'leg_range_breakout',
    controls: [
      {
        key: 'breakout_bar_starttime',
        type: 'time',
        defaultValue: '09:15',
        isRequired: true,
        enabled: true,
        width: 128,
      },
      {
        key: 'breakout_bar_endtime',
        type: 'time',
        defaultValue: '10:00',
        isRequired: true,
        enabled: true,
        width: 128,
      },
      {
        key: 'breakout_bar_field',
        type: 'dropdown',
        defaultValue: 'high',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_range_breakout',
        values: [
          {
            key: 'high',
            label: 'High',
          },
          {
            key: 'low',
            label: 'Low',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'range_breakout',
        type: 'checkbox',
        defaultValue: false,
        isRequired: false,
        enabled: true,
      },
    ],
  },

  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_breakout_on_underlying',
    name: 'Breakout on Underlying',
    renderer: CheckFieldRenderer,
    group: 2,
    controls: [
      {
        key: 'breakout_on_underlying',
        enabled: true,
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_contract_selection_strike_stap_size',
    name: 'Contract Multiplier',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'set_contract_multiplier',
    controls: [
      {
        key: 'contract_selection_strike_stap_size',
        tooltip: 'set_contract_multiplier',
        enabled: true,
        type: 'input',
        defaultValue: '0',
        isRequired: true,
        width: 192,
        closeButton: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_rr_type_tg',
    name: 'Re-Entry on Target',
    renderer: MultipleSelectAndInputRenderer,
    isAddButtonActive: true,
    group: 2,
    controls: [
      {
        key: 'tg_rr_type',
        type: 'dropdown',
        defaultValue: 'reentry',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'reentry',
            label: 'Re-Entry',
          },
          {
            key: 'reexecution',
            label: 'Re-Execution',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'tg_reentry_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: createRRTypeSelectionOptions(false),
      },
      {
        key: 'tg_reentry_count',
        type: 'input',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
      },
      {
        key: 'tg_delay_seconds',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: false,
        helpText: 'sec',
      },
      {
        key: 'tg_lazy_legs',
        type: 'multi_select',
        width: 100,
        defaultValue: 0,
        isRequired: true,
        values: createReEntryLegOptions(),
        enabled: false,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_rr_type_sl',
    name: 'Re-Entry on Stop Loss',
    renderer: MultipleSelectAndInputRenderer,
    isAddButtonActive: true,
    group: 2,
    controls: [
      {
        key: 'sl_rr_type',
        type: 'dropdown',
        defaultValue: 'reentry',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'reentry',
            label: 'Re-Entry',
          },
          {
            key: 'reexecution',
            label: 'Re-Execution',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'sl_reentry_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: createRRTypeSelectionOptions(false),
      },
      {
        key: 'sl_reentry_count',
        type: 'input',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
      },
      {
        key: 'sl_delay_seconds',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: false,
        helpText: 'sec',
      },
      {
        key: 'sl_lazy_legs',
        type: 'multi_select',
        width: 100,
        defaultValue: 0,
        isRequired: true,
        values: createReEntryLegOptions(),
        enabled: false,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_minimum_days_to_expiry',
    name: 'Minimum DTE',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,

    isAddButtonActive: true,
    uniqueKey: 'leg_minimum_days_to_expiry',
    controls: [
      {
        key: 'minimum_days_to_expiry',
        type: 'input',
        defaultValue: '-1',
        isRequired: true,
        enabled: true,
        width: 192,
        closeButton: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_maximum_days_to_expiry',
    name: 'Maximum DTE',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'leg_maximum_days_to_expiry',
    controls: [
      {
        key: 'maximum_days_to_expiry',
        type: 'input',
        defaultValue: '-1',
        isRequired: true,
        enabled: true,
        width: 192,
        closeButton: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_rollover_days_before_expiry',
    name: 'Rollover Days',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'leg_rollover_days_before_expiry',
    controls: [
      {
        key: 'rollover_days_before_expiry',
        type: 'input',
        defaultValue: '0',
        isRequired: false,
        enabled: true,
        width: 128,
      },
      {
        key: 'rollover_time',
        type: 'time',
        defaultValue: '00:00',
        isRequired: true,
        enabled: true,
        width: 158,
        closeButton: true,
      },
    ],
  },
  // leg level adiitional settings:
  // rollover_days_before_expiry: -1 (int)
  // rollover_time: 00:00 (timestemp)

  // minimum_days_to_expiry: -1 (int)
  // maximum_days_to_expiry: -1 (int)
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_trail_sl',
  //   name: 'Trail SL',
  //   renderer: CheckFieldRenderer,
  //   group: 2,
  //   controls: [
  //     {
  //       key: 'trail_sl',
  //       type: 'checkbox',
  //       defaultValue: false,
  //       isRequired: true,
  //       enabled: true,
  //     },
  //   ],
  // },
];
export const OptionFutureLegFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_direction',
    name: 'Direction',
    renderer: DropdownFieldRenderer,
    group: 0,
    width: 128,
    controls: [
      {
        key: 'direction',
        type: 'dropdown',
        defaultValue: 'long',
        isRequired: true,
        enabled: true,
        values: DirectionValueOptions,
      },
    ],
  },

  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_momentum',
    name: 'Momentum',
    renderer: SelectWithSingleInputRenderer,
    group: 1,
    uniqueKey: 'leg_momentum',
    isAddButtonActive: true,
    controls: [
      {
        key: 'momentum_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_momentum',
        values: createMomentumTypeSelectionOptions(''),
      },
      {
        key: 'momentum_value',
        type: 'input',
        defaultValue: 0,
        isRequired: false,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_combine_contract_selection_price_range',
    name: 'Price Range',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 0,
    hide: false,
    controls: [
      {
        key: 'contract_selection_price_range_start',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
      {
        key: 'contract_selection_price_range_end',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        width: 128,
      },
    ],
  },

  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_type',
    name: 'Type',
    renderer: InputFieldRenderer,
    group: 0,
    width: 128,
    hide: true,
    controls: [
      {
        key: 'type',
        type: 'input',
        defaultValue: 'option',
        isRequired: true,
        enabled: true,
      },
    ],
  },

  {
    validation: {
      status: true,
      error: '',
    },
    addClass: 'lots-parent',
    parentKey: 'parent_lots',
    name: 'Lots',
    renderer: InputFieldRenderer,
    group: 0,
    width: 128,
    controls: [
      {
        key: 'lots',
        type: 'input',
        defaultValue: 1,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_combine_expiry_type_value',
    name: 'Expiry',
    renderer: DropdownFieldRenderer,
    group: 0,
    width: 192,
    addClass: 'parent-ml-auto',
    controls: [
      {
        key: 'combine_expiry_type_value',
        type: 'dropdown',
        defaultValue: 'monthly_0',
        isRequired: true,
        enabled: true,
        values: createContractExpityTypeSelection(),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_expiry_type',
    name: 'Expiry Type',
    renderer: DropdownFieldRenderer,
    group: 0,
    controls: [
      {
        key: 'expiry_type',
        type: 'dropdown',
        defaultValue: 'weekly',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'weekly',
            label: 'weekly',
          },
          {
            key: 'monthly',
            label: 'monthly',
          },
        ],
      },
    ],
    hide: true,
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_expiry_value',
    name: 'Expiry Value',
    renderer: DropdownFieldRenderer,
    group: 1,
    controls: [
      {
        key: 'expiry_value',
        type: 'dropdown',
        defaultValue: '',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '0',
            label: 'Current',
          },
          {
            key: '1',
            label: 'Next',
          },
        ],
      },
    ],
    hide: true,
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_Target_Profit',
    name: 'Target Profit',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    uniqueKey: 'leg_target_profit',
    group: 1,
    controls: [
      {
        key: 'target_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        width: 300,
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Percentage',
          },
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'ul_points',
            label: 'UL Points',
          },
          {
            key: 'ul_percentage',
            label: 'UL Percentage',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'target_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        tooltip: 'leg_target_profit',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_stop_loss',
    name: 'Stop Loss',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 1,
    uniqueKey: 'leg_stop_loss',
    controls: [
      {
        key: 'sl_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Percentage',
          },
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'ul_points',
            label: 'UL Points',
          },
          {
            key: 'ul_percentage',
            label: 'UL Percentage',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'sl_value',
        type: 'input',
        defaultValue: '',
        isRequired: true,
        enabled: true,
        tooltip: 'leg_stop_loss',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_trail_stop_loss',
    name: 'Trail Stop Loss',
    renderer: SelectWithDoubleInputRenderer,
    group: 1,
    uniqueKey: 'leg_trail_stop_loss',
    isAddButtonActive: true,
    hide: true,
    controls: [
      {
        key: 'trailing_sl_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        tooltip: 'double input renderer',
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Percentage',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'trailing_sl_trigger_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        helpText: 'Stop Loss Movement',
      },
      {
        key: 'trailing_sl_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        helpText: 'Leg Movement',
        tooltip: 'leg_trail_stop_loss',
      },
      {
        key: 'trail_sl',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   type: 'selectwithinput',
  //   parentKey: 'parent_re_entry',
  //   name: 'Re-Entry',
  //   renderer: SelectWithSingleInputRenderer,
  //   group: 1,
  //   isAddButtonActive: true,
  //   controls: [
  //     {
  //       key: 'reentry_type',
  //       type: 'dropdown',
  //       defaultValue: 'none',
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         {
  //           key: 'ctc',
  //           label: 'Cost To Cost',
  //         },
  //         {
  //           key: 'Remove',
  //           label: 'Remove',
  //         },
  //       ],
  //     },
  //     {
  //       key: 'reentry_count',
  //       type: 'input',
  //       defaultValue: 0,
  //       isRequired: true,
  //       enabled: true,
  //     },
  //   ],
  // },

  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_trail_sl',
  //   name: 'Trail SL',
  //   renderer: CheckFieldRenderer,
  //   group: 2,
  //   controls: [
  //     {
  //       key: 'trail_sl',
  //       type: 'checkbox',
  //       defaultValue: false,
  //       isRequired: true,
  //       enabled: true,
  //     },
  //   ],
  // },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_minimum_days_to_expiry',
    name: 'Minimum DTE',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,

    isAddButtonActive: true,
    uniqueKey: 'leg_minimum_days_to_expiry',
    controls: [
      {
        key: 'minimum_days_to_expiry',
        type: 'input',
        defaultValue: '-1',
        isRequired: true,
        enabled: true,
        width: 192,
        closeButton: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_maximum_days_to_expiry',
    name: 'Maximum DTE',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'leg_maximum_days_to_expiry',
    controls: [
      {
        key: 'maximum_days_to_expiry',
        type: 'input',
        defaultValue: '-1',
        isRequired: true,
        enabled: true,
        width: 192,
        closeButton: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_rollover_days_before_expiry',
    name: 'Rollover Days',
    type: 'selectwithinput',
    renderer: MultipleSelectAndInputRenderer,
    group: 2,
    isAddButtonActive: true,
    uniqueKey: 'leg_rollover_days_before_expiry',
    controls: [
      {
        key: 'rollover_days_before_expiry',
        type: 'input',
        defaultValue: '0',
        isRequired: false,
        enabled: true,
        width: 128,
      },
      {
        key: 'rollover_time',
        type: 'time',
        defaultValue: '00:00',
        isRequired: true,
        enabled: true,
        width: 158,
        closeButton: true,
      },
    ],
  },
];
export function createMomentumTypeSelectionOptions(
  botBuilderType: string | undefined,
) {
  const values = [
    {
      key: 'points',
      label: 'Points',
    },
    {
      key: 'percentage',
      label: 'Percentage',
    },
    {
      key: 'points_up',
      label: 'Points Up',
    },
    {
      key: 'points_down',
      label: 'Points Down',
    },
    {
      key: 'percentage_up',
      label: 'Percentage Up',
    },
    {
      key: 'percentage_down',
      label: 'Percentage Down',
    },
  ];
  if (botBuilderType && !botBuilderType.includes('crypto')) {
    values.push(
      {
        key: 'underlying_points_up',
        label: 'UL Points Up',
      },
      {
        key: 'underlying_points_down',
        label: 'UL Points Down',
      },
      {
        key: 'underlying_percentage_up',
        label: 'UL Percentage Up',
      },
      {
        key: 'underlying_percentage_down',
        label: 'UL Percentage Down',
      },
      {
        key: 'Remove',
        label: 'Remove',
      },
    );
  } else {
    values.push({
      key: 'Remove',
      label: 'Remove',
    });
  }
  return values;
}

export const OptionLegFieldsGroupWise: FieldParam[] = OptionLegFields.reduce(
  (acc: FieldParam[][], curr: FieldParam) => {
    if (!acc[curr.group]) {
      acc[curr.group] = [curr];
    } else {
      acc[curr.group].push(curr);
    }
    return acc;
  },
  [],
).flat();
export const OptionFutureLegFieldsGroupWise: FieldParam[] =
  OptionFutureLegFields.reduce((acc: FieldParam[][], curr: FieldParam) => {
    if (!acc[curr.group]) {
      acc[curr.group] = [curr];
    } else {
      acc[curr.group].push(curr);
    }
    return acc;
  }, []).flat();

export const OptionSetFields: FieldParam[] = [
  /*
  controls: {
    key: '',
    type: '',
    defaultValue: '',
    isRequired: true,
    enabled: true,
  },{
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_',
    name: 'Name',
    key: 'name',
    enabled: true,
    renderer: InputFieldRenderer,
    type: 'input',
    defaultValue: 'S1',
    group: 0,
  },
  {
    controls: {
      key: '',
      type: '',
      defaultValue: '',
      isRequired: true,
      enabled: true,
    },
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_',
    name: 'Type',
    key: 'type',
    enabled: true,
    renderer: RadioButtonsRenderer,
    type: 'radio',
    values: [
      {
        key: 'time_based',
        label: 'time_based',
      },
    ],
    defaultValue: 'time_based',
    group: 0,
 }, */

  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_symbol',
    renderer: DropdownFieldRenderer,
    name: 'Symbol',
    width: 200,
    group: 0,
    renderingType: 'horizontal',
    controls: [
      {
        key: 'symbol',
        type: 'togglebuttongroup',
        defaultValue: NIFTY_SYMBOL,
        isRequired: true,
        enabled: true,
        values: createTradingSymbolSelectionOptions({}),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_holding_type',
    renderer: ToggleButtonGroupRenderer,
    group: 0,
    renderingType: 'horizontal',
    addClass: 'holding_type_parent',
    myStyle: {
      root: {
        'div.holding_type_parent': {
          marginLeft: '20px',
        },
      },
      small: {
        'div.holding_type_parent': {
          marginLeft: '0',
        },
      },
      medium: {
        'div.holding_type_parent': {
          marginLeft: '15px',
        },
      },
    },
    tooltip: 'buttonGroupRenederer',
    controls: [
      {
        key: 'holding_type',
        type: 'togglebuttongroup',
        defaultValue: 'mis',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'mis',
            label: 'Intraday',
          },
          //// Do not remove
          // {
          //   key: 'cnc',
          //   label: 'Positional',
          // },
          {
            key: 'cnc',
            label: 'STBT/BTST',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_position_type',
    renderer: ToggleButtonGroupRenderer,
    group: 0,
    renderingType: 'horizontal',
    addClass: 'position_type_parent',
    myStyle: {
      root: {
        'div.position_type_parent': {
          marginLeft: '20px',
        },
      },
      small: {
        'div.position_type_parent': {
          marginLeft: '0',
        },
      },
      medium: {
        'div.position_type_parent': {
          marginLeft: '15px',
        },
      },
    },
    uniqueKey: 'parent_position_type',
    controls: [
      {
        key: 'position_type',
        type: 'togglebuttongroup',
        defaultValue: 'intraday',
        isRequired: true,
        enabled: true,
        values: createButtonGroupOptions(),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_square_off_all_legs',
    name: '',
    renderer: RadioButtonsRenderer,
    type: 'radio',
    group: 2,
    controls: [
      {
        key: 'square_off_all_legs',
        type: 'radio',
        defaultValue: 'false',
        isRequired: true,
        enabled: true,
        tooltip: 'square_off_all_legs',
        values: [
          {
            key: 'false',
            label: 'Square Off One Leg',
          },
          {
            key: 'true',
            label: 'Square Off All Legs',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_move_to_cost',
    name: 'Move Stop Loss to Cost',
    renderer: CheckFieldRenderer,
    group: 3,
    controls: [
      {
        key: 'move_to_cost',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
        tooltip: 'set_move_sl_to_cost',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_enter_if_missed',
    name: 'Enter If Missed',
    renderer: CheckFieldRenderer,
    group: 3,
    controls: [
      {
        key: 'entry_if_missed',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
        tooltip: 'enter_if_missed',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_is_momentum_trade',
    name: 'Momentum Entry',
    hide: true,
    renderer: CheckFieldRenderer,
    controls: [
      {
        key: 'is_momentum_trade',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
      },
    ],
    group: 3,
  },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_symbol',
  //   name: 'Symbol',
  //   renderer: DropdownFieldRenderer,
  //   group: 4,
  //   width: 200,
  //   controls: [
  //     {
  //       key: 'symbol',
  //       type: 'dropdown',
  //       defaultValue: NIFTY_SYMBOL,
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         {
  //           key: NIFTY_SYMBOL,
  //           label: 'NIFTY 50 INDEX',
  //         },
  //         {
  //           key: 'NIFTY_SPACE_BANK',
  //           label: 'NIFTY BANK INDEX',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   validation: {
  //     status: true,
  //     error: '',
  //   },
  //   parentKey: 'parent_holding_type',
  //   name: 'Holding Type',
  //   renderer: DropdownFieldRenderer,
  //   group: 4,
  //   width: 400,
  //   controls: [
  //     {
  //       key: 'holding_type',
  //       type: 'dropdown',
  //       defaultValue: 'mis',
  //       isRequired: true,
  //       enabled: true,
  //       values: [
  //         {
  //           key: 'mis',
  //           label: 'Intraday',
  //         },
  //         {
  //           key: 'cnc',
  //           label: 'Cash & Carry',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_positional_expire_on',
    name: 'Positional Expiry On',
    width: 200,
    hide: false,
    renderer: DropdownFieldRenderer,
    type: 'dropdown',
    group: 4,
    controls: [
      {
        key: 'positional_expire_on',
        type: 'dropdown',
        defaultValue: 'weekly',
        isRequired: false,
        enabled: true,
        values: [
          {
            key: 'weekly',
            label: 'Weekly',
          },
          {
            key: 'monthly',
            label: 'Monthly',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_entry_time',
    name: 'Entry time',
    renderer: TimeRenderer,
    group: 4,
    controls: [
      {
        key: 'entry_time',
        type: 'time',
        defaultValue: '09:20',
        isRequired: true,
        enabled: true,
      },
      {
        key: 'days_to_expire_entry',
        type: 'dropdown',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
        values: createDaysToExpireSelectionOptions('weekly', false, '0'),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_max_allowed_entry_time',
    name: 'No Entry After',
    renderer: TimeRenderer,
    group: 4,
    controls: [
      {
        key: 'max_allowed_entry_time',
        type: 'time',
        defaultValue: '15:00',
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_exit_time',
    name: 'Exit time',
    renderer: TimeRenderer,
    group: 4,
    controls: [
      {
        key: 'exit_time',
        type: 'time',
        defaultValue: '15:00',
        isRequired: true,
        enabled: true,
      },
      {
        key: 'days_to_expire_exit',
        type: 'dropdown',
        defaultValue: '0',
        isRequired: true,
        enabled: true,
        values: createDaysToExpireSelectionOptions('weekly', true, '0'),
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_days_to_run',
    name: 'Days To Run',
    renderer: ButtonGroupRenderer,
    width: 352,
    group: 4,
    renderingType: 'horizontal',
    controls: [
      {
        key: 'days_to_run',
        type: 'buttongroup',
        defaultValue: ['1', '2', '3', '4', '5'],
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '1',
            label: 'Mo',
          },
          {
            key: '2',
            label: 'Tu',
          },
          {
            key: '3',
            label: 'We',
          },
          {
            key: '4',
            label: 'Th',
          },
          {
            key: '5',
            label: 'Fr',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_is_stbt_btst',
    name: 'Is STBT/BTST',
    renderer: CheckFieldRenderer,
    group: 5,
    hide: true,
    controls: [
      {
        key: 'is_stbt_btst',
        enabled: true,
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
      },
    ],
  },

  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_strategy_target_profit',
    name: 'Set Target Profit',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 5,
    uniqueKey: 'set_target_profit',
    controls: [
      {
        key: 'mtm_target_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Total Premium %',
          },
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'mtm_target_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        tooltip: 'set_target_profit',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_strategy_stop_loss',
    name: 'Set Stop Loss',
    renderer: SelectWithSingleInputRenderer,
    group: 5,
    isAddButtonActive: true,
    uniqueKey: 'set_stop_loss',
    controls: [
      {
        key: 'mtm_sl_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'points',
            label: 'Points',
          },
          {
            key: 'percentage',
            label: 'Total Premium %',
          },
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'mtm_sl_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
        tooltip: 'set_stop_loss',
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_reexecution',
    name: 'Re-Execution',
    renderer: MultipleSelectAndInputRenderer,
    group: 5,
    isAddButtonActive: true,
    uniqueKey: 'set_re_execution',
    controls: [
      {
        key: 'reexecution_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'immediate',
            label: 'Immediate',
          },
          {
            key: 'delay',
            label: 'Delay',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'reexecution_count',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'reexecution_delay_seconds',
        type: 'input',
        width: 110,
        defaultValue: 0,
        isRequired: true,
        enabled: false,
        helpText: 'sec',
      },
      {
        key: 'reexecution_dependency',
        type: 'dropdown',
        defaultValue: 'sl',
        isRequired: true,
        enabled: true,
        tooltip: 'set_re_execution',
        values: [
          {
            key: 'sl',
            label: 'SL',
          },
          {
            key: 'tg',
            label: 'TG',
          },
          {
            key: 'sltg',
            label: 'SL/TG',
          },
          {
            key: 'any',
            label: 'Any',
          },
        ],
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_combined_momentum_type',
    name: 'Momentum',
    renderer: SelectWithSingleInputRenderer,
    group: 5,
    isAddButtonActive: true,
    controls: [
      {
        key: 'combined_momentum_type',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'points_up',
            label: 'Points Up',
          },
          {
            key: 'points_down',
            label: 'Points Down',
          },
          {
            key: 'percentage_up',
            label: 'Percentage Up',
          },
          {
            key: 'percentage_down',
            label: 'Percentage Down',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'combined_momentum_value',
        type: 'input',
        defaultValue: 0,
        isRequired: false,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_mtm_lock_target_profit_unit',
    name: 'Lock Profit',
    renderer: SelectWithDoubleInputRenderer,
    group: 5,
    isAddButtonActive: true,
    controls: [
      {
        key: 'mtm_lock_target_profit_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'mtm_target_profit_reaches',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'mtm_lock_target',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_mtm_trail_lock_profit_unit',
    name: 'Trail Lock Profit',
    renderer: SelectWithDoubleInputRenderer,
    group: 5,
    hide: true,
    isAddButtonActive: true,
    controls: [
      {
        key: 'mtm_trail_lock_profit_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'mtm_target_profit_trail_every',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'mtm_trail_lock_profit_by',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
];

export const OptionSetFieldsGroupWise: FieldParam[] = OptionSetFields.reduce(
  (acc: FieldParam[][], curr: FieldParam) => {
    if (!acc[curr.group]) {
      acc[curr.group] = [curr];
    } else {
      acc[curr.group].push(curr);
    }
    return acc;
  },
  [],
).flat();

export const OptionStrategyFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_start_date',
    name: 'Start Date',
    renderer: DateRenderer,
    group: 0,
    controls: [
      {
        key: 'start_date',
        type: 'date',
        defaultValue: formatDateString(
          new Date(new Date().setDate(new Date().getDate() - 30)),
          'DD/MM/YYYY',
        ),
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_end_date',
    name: 'End Date',
    renderer: DateRenderer,
    group: 1,
    controls: [
      {
        key: 'end_date',
        type: 'date',
        defaultValue: formatDateString(
          new Date(new Date().setDate(new Date().getDate() - 1)),
          'DD/MM/YYYY',
        ),
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_initial_capital',
    name: 'Initial Capital',
    renderer: InputFieldRenderer,
    group: 2,
    controls: [
      {
        key: 'initial_capital',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
];

export const calculateOptionSetParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionSetFields.forEach((optionSet: FieldParam) => {
    optionSet.controls.forEach(optionField => {
      returnData[optionField.key] = {
        parentKey: optionSet.parentKey,
        type: optionSet.type ? optionSet.type : '',
      };
    });
  });
  return returnData;
};

export const calculateOptionLegParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionLegFields.forEach((optionLeg: FieldParam) => {
    optionLeg.controls.forEach(optionLegField => {
      returnData[optionLegField.key] = {
        parentKey: optionLeg.parentKey,
        type: optionLeg.type ? optionLeg.type : '',
      };
    });
  });
  return returnData;
};

export const OptionStrategyStopLossFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_strategy_stop_loss',
    name: 'Strategy Stop Loss',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 0,
    controls: [
      {
        key: 'unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_days_to_run',
    name: 'Days To Run',
    renderer: ButtonGroupRenderer,
    width: 352,
    group: 0,
    renderingType: 'horizontal',
    controls: [
      {
        key: 'days_to_run',
        type: 'buttongroup',
        defaultValue: ['1', '2', '3', '4', '5'],
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '1',
            label: 'Mo',
          },
          {
            key: '2',
            label: 'Tu',
          },
          {
            key: '3',
            label: 'We',
          },
          {
            key: '4',
            label: 'Th',
          },
          {
            key: '5',
            label: 'Fr',
          },
        ],
      },
    ],
  },
];
export const OptionStrategyLockProfitFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_daywise_lock_target_profit',
    name: 'Lock Profit',
    renderer: SelectWithDoubleInputRenderer,
    group: 5,
    isAddButtonActive: true,
    controls: [
      {
        key: 'daywise_lock_target_profit_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'daywise_target_profit_reaches',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'daywise_lock_target',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_daywise_trail_lock_profit',
    name: 'Trail Lock Profit',
    renderer: SelectWithDoubleInputRenderer,
    group: 5,
    hide: true,
    width: 400,
    isAddButtonActive: true,
    controls: [
      {
        key: 'daywise_trail_lock_profit_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'daywise_target_profit_trail_every',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'daywise_trail_lock_profit_by',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        tooltip: 'set_daywise_trail_lock_profit',
        key: 'daywise_trail_activation_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
];

export const OptionStrategyStopLossFieldsGroupWise: FieldParam[] =
  OptionStrategyStopLossFields.reduce(
    (acc: FieldParam[][], curr: FieldParam) => {
      if (!acc[curr.group]) {
        acc[curr.group] = [curr];
      } else {
        acc[curr.group].push(curr);
      }
      return acc;
    },
    [],
  ).flat();

export const OptionStrategyLockProfitFieldsGroupWise: FieldParam[] =
  OptionStrategyLockProfitFields.reduce(
    (acc: FieldParam[][], curr: FieldParam) => {
      if (!acc[curr.group]) {
        acc[curr.group] = [curr];
      } else {
        acc[curr.group].push(curr);
      }
      return acc;
    },
    [],
  ).flat();

export const calculateOptionStopLossFieldsParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionStrategyStopLossFields.forEach((optionSet: FieldParam) => {
    optionSet.controls.forEach(optionField => {
      returnData[optionField.key] = {
        parentKey: optionSet.parentKey,
        type: optionSet.type ? optionSet.type : '',
      };
    });
  });
  return returnData;
};
export const calculateOptionLockProfitFieldsParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionStrategyLockProfitFields.forEach((optionSet: FieldParam) => {
    optionSet.controls.forEach(optionField => {
      returnData[optionField.key] = {
        parentKey: optionSet.parentKey,
        type: optionSet.type ? optionSet.type : '',
      };
    });
  });
  return returnData;
};

export const OptionStrategyTargetProfitFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_strategy_target_profityy',
    name: 'Strategy Target Profit',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 0,
    controls: [
      {
        key: 'unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_days_to_run',
    name: 'Days To Run',
    renderer: ButtonGroupRenderer,
    width: 352,
    group: 0,
    renderingType: 'horizontal',
    controls: [
      {
        key: 'days_to_run',
        type: 'buttongroup',
        defaultValue: ['1', '2', '3', '4', '5'],
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '1',
            label: 'Mo',
          },
          {
            key: '2',
            label: 'Tu',
          },
          {
            key: '3',
            label: 'We',
          },
          {
            key: '4',
            label: 'Th',
          },
          {
            key: '5',
            label: 'Fr',
          },
        ],
      },
    ],
  },
];

export const OptionStrategyTargetProfitFieldsGroupWise: FieldParam[] =
  OptionStrategyTargetProfitFields.reduce(
    (acc: FieldParam[][], curr: FieldParam) => {
      if (!acc[curr.group]) {
        acc[curr.group] = [curr];
      } else {
        acc[curr.group].push(curr);
      }
      return acc;
    },
    [],
  ).flat();

export const calculateOptionTargetProfitFieldsParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionStrategyTargetProfitFields.forEach((optionSet: FieldParam) => {
    optionSet.controls.forEach(optionField => {
      returnData[optionField.key] = {
        parentKey: optionSet.parentKey,
        type: optionSet.type ? optionSet.type : '',
      };
    });
  });
  return returnData;
};

export const OptionStrategyTrailingStopLossFields: FieldParam[] = [
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_strategy_trailing_stop_loss',
    name: 'Trail Start At',
    renderer: SelectWithSingleInputRenderer,
    isAddButtonActive: true,
    group: 0,
    controls: [
      {
        key: 'activation_unit',
        type: 'dropdown',
        defaultValue: 'none',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'activation_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    type: 'selectwithinput',
    parentKey: 'parent_trail_stop_loss',
    name: 'Trail Stop Loss',
    renderer: SelectWithDoubleInputRenderer,
    group: 0,
    isAddButtonActive: true,
    controls: [
      {
        tooltip: 'doubleinput renderer',
        key: 'movement_type',
        type: 'dropdown',
        defaultValue: 'absolute',
        isRequired: true,
        enabled: true,
        values: [
          {
            key: 'absolute',
            label: 'Absolute',
          },
          {
            key: 'Remove',
            label: 'Remove',
          },
        ],
      },
      {
        key: 'movement_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'trail_value',
        type: 'input',
        defaultValue: 0,
        isRequired: true,
        enabled: true,
      },
      {
        key: 'trail_sl',
        type: 'checkbox',
        defaultValue: false,
        isRequired: true,
        enabled: true,
      },
    ],
  },
  {
    validation: {
      status: true,
      error: '',
    },
    parentKey: 'parent_days_to_run',
    name: 'Days To Run',
    renderer: ButtonGroupRenderer,
    width: 352,
    group: 0,
    renderingType: 'horizontal',
    controls: [
      {
        key: 'days_to_run',
        type: 'buttongroup',
        defaultValue: ['1', '2', '3', '4', '5'],
        isRequired: true,
        enabled: true,
        values: [
          {
            key: '1',
            label: 'Mo',
          },
          {
            key: '2',
            label: 'Tu',
          },
          {
            key: '3',
            label: 'We',
          },
          {
            key: '4',
            label: 'Th',
          },
          {
            key: '5',
            label: 'Fr',
          },
        ],
      },
    ],
  },
];

export const OptionStrategyTrailingStopLossFieldsGroupWise: FieldParam[] =
  OptionStrategyTrailingStopLossFields.reduce(
    (acc: FieldParam[][], curr: FieldParam) => {
      if (!acc[curr.group]) {
        acc[curr.group] = [curr];
      } else {
        acc[curr.group].push(curr);
      }
      return acc;
    },
    [],
  ).flat();

export const calculateOptionTrailingStopLossFieldsParentKey = () => {
  const returnData: Record<string, Record<string, string>> = {};
  OptionStrategyTrailingStopLossFields.forEach((optionSet: FieldParam) => {
    optionSet.controls.forEach(optionField => {
      returnData[optionField.key] = {
        parentKey: optionSet.parentKey,
        type: optionSet.type ? optionSet.type : '',
      };
    });
  });
  return returnData;
};
