import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
  useContext,
} from 'react';
import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  InputLabel,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  FieldParam,
  DropdownFieldValue,
  SelectWithDoubleInputParam,
  FieldParamValue,
  ValidateState,
  ControlsType,
  OptionLegParam,
} from '../types';
import { cloneDeep } from 'lodash';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { generateValuesBeforeSend } from '../Utils/utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from 'app/design/uiComponents/CustomTooltip';
import { Close } from '@mui/icons-material';
import { MultiSelection } from './MultiSelection';
import { ToastContext } from 'app/components/Context/ToastContext';
import { useTranslation } from 'react-i18next';

type Param = Omit<FieldParam, 'renderer' | 'type'>;
interface Props {
  param: Param;
  selected: Record<string, FieldParamValue>;
  onChange: (key: string, value: Record<string, FieldParamValue>) => void;
  sendValidation: (key: string, isValid: boolean) => void;
  isReadOnly: boolean;
  optionLeg?: OptionLegParam;
}

export const MultipleSelectAndInputRenderer = (props: Props) => {
  const { param, onChange, sendValidation, isReadOnly } = props;
  let selected = props.selected;
  const {
    name,
    controls,
    parentKey,
    myStyle,
    addClass,
    width,
    isAddButtonActive,
  } = param as SelectWithDoubleInputParam;
  const [isValidStatus, setIsValidStatus] = useState<ValidateState>({
    error: '',
    status: false,
  });
  const globaltheme = useTheme();
  const [userInput, setUserInput] = useState(selected);
  const [showAddCheckbox, setShowAddCheckbox] = useState<boolean>(
    props.selected.toggleStatus as boolean,
  );
  const { showAlert } = useContext(ToastContext);
  const { t } = useTranslation();

  const validate = useCallback(
    (formData: Record<string, FieldParamValue | DropdownFieldValue[]>) => {
      if (Object.prototype.hasOwnProperty.call(formData, 'toggleStatus')) {
        delete formData.toggleStatus;
      }
      let validStatus = {
        status: false,
        error: '',
      };
      if (!isAddButtonActive || !selected.toggleStatus) {
        controls.forEach((control, index) => {
          if (
            !Object.values(formData).includes('none') &&
            (!formData[control.key] ||
              (formData[control.key] && formData[control.key] === undefined)) &&
            controls[index].isRequired &&
            controls[index].enabled
          ) {
            validStatus = {
              status: true,
              error: `Please enter valid ${
                control.type === 'dropdown' ? 'dropdown' : 'input'
              } data`,
            };
          }
        });
      }
      return validStatus;
    },
    [controls, selected, isAddButtonActive],
  );

  useEffect(() => {
    const validStatus = validate(cloneDeep(selected));
    setIsValidStatus(validStatus);
    sendValidation(parentKey, !validStatus.status);

    setShowAddCheckbox(
      isReadOnly ? !isReadOnly : (props.selected.toggleStatus as boolean),
    );
  }, [
    validate,
    parentKey,
    sendValidation,
    selected,
    props.selected.toggleStatus,
    isReadOnly,
  ]);

  const onChangeFieldType = (
    event: SelectChangeEvent<string>,
    control: ControlsType,
  ) => {
    if (event.target.value === 'Remove') {
      const checkboxField = controls.find(e => e.type === 'checkbox');
      if (
        checkboxField &&
        Object.prototype.hasOwnProperty.call(selected, checkboxField.key)
      ) {
        selected[checkboxField.key] = false;
      }
      setShowAddCheckbox(!showAddCheckbox);
      sendValidation(parentKey, true);
      Object.keys(selected).forEach(item => {
        const currentItem = controls.find(e => e.key === item);
        if (item === control.key) {
          selected[item] = control.defaultValue as string;
        } else {
          selected[item] = currentItem?.defaultValue
            ? currentItem.defaultValue
            : selected[item];
        }
      });
      selected.toggleStatus = true;
      onChange(parentKey, selected);
    } else {
      selected[control.key] = event.target.value;
      const validStatus = validate(cloneDeep(selected));
      sendValidation(parentKey, !validStatus.status);
      onChange(parentKey, selected);
    }
  };
  const onChangeMultiSelect = (value: string[], control: ControlsType) => {
    selected[control.key] = value;
    onChange(parentKey, selected);
  };

  const theme = useMemo(() => {
    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                color: globaltheme.palette.text.disabled, // For text color in disabled state
                // backgroundColor: globaltheme.palette.action.disabledBackground, // Optional: change background
              },
            },
            input: {
              color: globaltheme.palette.text.primary,
              '&.Mui-disabled': {
                color: globaltheme.palette.text.disabled,
                ' -webkit-text-fill-color': globaltheme.palette.text.disabled,
              },
            },
            notchedOutline: {
              border: 'none',
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              display: `${isReadOnly ? 'none' : 'block'}`,
              color: globaltheme.palette.text.secondary,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              backgroundColor: globaltheme.component.mainLayoutContainer.main,
              padding: '0px 7px',
            },
            shrink: {
              top: '0px',
            },
          },
        },

        MuiTextField: {
          styleOverrides: {
            root: {
              maxWidth: '72px',
              color: 'white',
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: globaltheme.palette.common.white,
              color: globaltheme.palette.common.black,
            },
          },
        },
      },
    });
  }, [isReadOnly, globaltheme]);

  const onInputFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    control: ControlsType,
  ) => {
    selected[control.key] = event.target.value;
    const validStatus = validate(cloneDeep(selected));
    setIsValidStatus(validStatus);
    setUserInput(selected);
  };

  const onFocusOutHandler = () => {
    const validStatus = validate(cloneDeep(userInput));
    sendValidation(parentKey, !validStatus.status);
    onChange(parentKey, selected);
  };
  const filteredControls = controls.filter(x => x.enabled);

  return (
    <>
      {isReadOnly && controls.some(e => selected[e.key] === 'none') ? null : (
        <Grid
          item
          className={addClass}
          style={
            width
              ? {
                  marginBottom: '24px',
                  alignSelf: 'center',
                  width: `${width}px`,
                }
              : { marginBottom: '24px', alignSelf: 'center' }
          }
        >
          <GlobalStyles
            styles={{
              [theme.breakpoints.down('md')]: myStyle?.small
                ? myStyle.small
                : myStyle?.root
                ? myStyle.root
                : myStyle,
              [theme.breakpoints.up('md')]: myStyle?.medium
                ? myStyle.medium
                : myStyle?.root
                ? myStyle.root
                : myStyle,
              [theme.breakpoints.up('lg')]: myStyle?.large
                ? myStyle.large
                : myStyle?.root
                ? myStyle.root
                : myStyle,
            }}
          />
          {(showAddCheckbox && isAddButtonActive) ||
          controls.some(e => selected[e.key] === 'none') ? (
            <CustomTooltip tooltipKey={param.uniqueKey}>
              {() => (
                <Button
                  variant="outlined"
                  startIcon={<AddCircleIcon />}
                  onClick={() => {
                    const checkboxField = controls.find(
                      e => e.type === 'checkbox',
                    );
                    if (
                      checkboxField &&
                      Object.prototype.hasOwnProperty.call(
                        selected,
                        checkboxField.key,
                      )
                    ) {
                      selected[checkboxField.key] = true;
                    }
                    if (param.parentKey == 'parent_rr_type') {
                      if (
                        (props.optionLeg &&
                          props.optionLeg.parent_rr_type_tg[
                            'tg_reentry_count'
                          ] > 0) ||
                        (props.optionLeg &&
                          props.optionLeg.parent_rr_type_sl[
                            'sl_reentry_count'
                          ] > 0)
                      ) {
                        showAlert!(
                          t(
                            'user_bot_startegy.option_builder.warnings.re_entry_warning',
                          ),
                          'warning',
                        );
                      }
                    } else if (
                      param.parentKey == 'parent_rr_type_sl' ||
                      param.parentKey == 'parent_rr_type_tg'
                    ) {
                      if (
                        props.optionLeg &&
                        props.optionLeg.parent_rr_type['reentry_count'] > 0
                      ) {
                        showAlert!(
                          t(
                            'user_bot_startegy.option_builder.warnings.re_entry_warning',
                          ),
                          'warning',
                        );
                      }
                    }
                    selected.toggleStatus = false;
                    setShowAddCheckbox(!showAddCheckbox);
                    const validStatus = validate(cloneDeep(userInput));
                    sendValidation(parentKey, !validStatus.status);
                    selected = generateValuesBeforeSend(param, selected);
                    onChange(parentKey, selected);
                  }}
                  className="remove-border"
                >
                  {name}
                </Button>
              )}
            </CustomTooltip>
          ) : (
            <FormControl
              className={`multi-select-box ${
                isValidStatus.status ? 'error' : ''
              } ${width ? 'full-width' : ''}`}
            >
              <ThemeProvider theme={theme}>
                <InputLabel
                  sx={{ color: globaltheme.palette.text.secondary }}
                  shrink
                >
                  {name}
                </InputLabel>
                {filteredControls.map((control, i) =>
                  control.enabled ? (
                    <Fragment key={control.key}>
                      {i !== 0 && control.type !== 'checkbox' ? (
                        <Box
                          sx={{
                            height: '36px',
                            borderLeft: '1px solid #ccc !important',
                          }}
                        />
                      ) : null}
                      <FormControl key={control.key}>
                        {control.type === 'dropdown' ? (
                          <Select
                            className=""
                            onChange={(event: SelectChangeEvent<string>) => {
                              onChangeFieldType(event, control);
                            }}
                            key={control.key}
                            name={control.key}
                            id={name}
                            value={selected[control.key] as string}
                            disabled={isReadOnly}
                            error={isValidStatus.status}
                            sx={
                              control.width
                                ? {
                                    width: control.width,
                                  }
                                : {}
                            }
                            endAdornment={
                              control.tooltip && (
                                <InputAdornment sx={{ mr: 2 }} position="start">
                                  <CustomTooltip tooltipKey={control.tooltip}>
                                    {({ isTooltipAvailable }) =>
                                      isTooltipAvailable ? (
                                        <IconButton>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </CustomTooltip>
                                </InputAdornment>
                              )
                            }
                          >
                            {control.values?.map(
                              (value: DropdownFieldValue) => (
                                <MenuItem
                                  key={value.key}
                                  value={value.key}
                                  className="select-option"
                                >
                                  {value.label}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        ) : ['input_text', 'input', 'time'].includes(
                            control.type,
                          ) ? (
                          <TextField
                            variant="outlined"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const value = event.target.value;
                              if (
                                control.key ===
                                  'contract_selection_delta_range_start' ||
                                control.key ===
                                  'contract_selection_delta_range_end'
                              ) {
                                if (Number(value) >= 0 && Number(value) <= 1) {
                                  onInputFieldChange(event, control);
                                }
                              } else if (!value.includes('.')) {
                                onInputFieldChange(event, control);
                              }
                            }}
                            name={control.key}
                            onBlur={onFocusOutHandler}
                            value={selected[control.key] as string}
                            error={isValidStatus.status}
                            disabled={isReadOnly}
                            InputProps={{
                              endAdornment: (control.helpText ||
                                control.closeButton) && (
                                <InputAdornment position="end">
                                  {control.closeButton && (
                                    <Close
                                      sx={{ cursor: 'pointer' }}
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      onClick={(e: any) => {
                                        // selected['toggleStatus'] = true;
                                        // setShowAddCheckbox(!showAddCheckbox);
                                        // sendValidation(parentKey, true);
                                        // // eslint-disable-next-line no-console
                                        // console.log(
                                        //   selected[control.key],
                                        //   selected,
                                        // );
                                        // sendValidation(parentKey, true);
                                        // Object.keys(selected).forEach(item => {
                                        //   const currentItem = controls.find(
                                        //     e => e.key === item,
                                        //   );
                                        //   if (item === control.key) {
                                        //     selected[item] =
                                        //       control.defaultValue as string;
                                        //   } else {
                                        //     selected[item] =
                                        //       currentItem?.defaultValue
                                        //         ? currentItem.defaultValue
                                        //         : selected[item];
                                        //   }
                                        // });
                                        // selected.toggleStatus = true;
                                        // onChange(parentKey, selected);
                                        onChangeFieldType(
                                          {
                                            ...e, // if you want to retain other properties of the event
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                            target: {
                                              ...e.target, // retain other properties of the target if needed
                                              value: 'Remove',
                                            },
                                          } as SelectChangeEvent<string>,
                                          control,
                                        );
                                      }}
                                    />
                                  )}
                                  <Typography>{control.helpText}</Typography>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              ...(control.width && {
                                maxWidth: control.width,
                                width: control.width,
                              }),
                            }}
                            {...(control.type === 'time'
                              ? { type: 'time' }
                              : control.type === 'input_text'
                              ? { type: 'text' }
                              : { type: 'number' })}
                          />
                        ) : control.type === 'multi_select' ? (
                          <MultiSelection
                            options={control.values}
                            control={control}
                            selected={selected[control.key] as string[]}
                            onchange={onChangeMultiSelect}
                          />
                        ) : null}
                      </FormControl>
                    </Fragment>
                  ) : null,
                )}
              </ThemeProvider>
            </FormControl>
          )}
        </Grid>
      )}
    </>
  );
};
