import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { useQuery } from 'react-query';
import { GetMTMData } from 'services/apiServices';
import { Errors, GetMTMDataResult } from 'types/ApiServicesTypes';
import { FEATURES } from 'types/Feature';

export default function useGetMTMData(open: boolean, botId: number) {
  const isShowMTMGraph = useIsFeatureFlag([FEATURES.IS_SHOW_MTM_GRAPH]);
  const objQuery = useQuery<GetMTMDataResult, Errors>(
    ['GetMTMData'],
    () => GetMTMData(botId),
    {
      refetchInterval: open && 60000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      enabled: isShowMTMGraph && !Number.isNaN(botId),
    },
  );

  return objQuery;
}
