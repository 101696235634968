// Service
import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { PositionsListLayoutProps } from 'types/ComponentTypes';
import { Position, GetBotPositionsResult } from 'types/ApiServicesTypes';
// import BotPositionRowDetail from './BotPositionRowDetail';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { createAllTodaysPositionTableRows } from '../Bots/utils';
import { SymbolsData } from 'types/Symbols';

// const DetailsRowRenderer = ({
//   rowData,
//   handleClose,
// }: {
//   rowData: Position;
//   handleClose: () => void;
// }) => {
//   return <BotPositionRowDetail position={rowData} handleClose={handleClose} />;
// };

const GetAllBotPositionTable = ({
  data,
  handleChartRowClick,
  handleClick,
  liveFeedData,
}: {
  data: PositionsListLayoutProps | Position[] | GetBotPositionsResult;
  handleChartRowClick?: (positionData: Position) => void;
  handleClick?: (positionData: Position) => void;
  liveFeedData?: SymbolsData;
}) => {
  const isChargesAndProfit = useIsFeatureFlag([FEATURES.IS_CHARGES_AND_PROFIT]);
  const isShowDecimalprecision = useIsFeatureFlag([
    FEATURES.IS_SHOW_DECIMAL_PRECISION,
  ]);
  const { columns, rows } = createAllTodaysPositionTableRows(
    data,
    isChargesAndProfit,
    liveFeedData,
    handleClick,
    isShowDecimalprecision,
  );

  return (
    <>
      <Box sx={{ px: 3, mx: 1 }}>
        <CommonTable
          whichTable={'alltodaysposition'}
          rows={rows}
          columns={columns}
          // DetailsRowRenderer={DetailsRowRenderer}
          handleChartRowClick={handleChartRowClick}
        />
      </Box>
    </>
  );
};

export default GetAllBotPositionTable;
