import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import { TradesType } from '../types';
import { Box } from '@mui/material';
import MuiButton from 'app/design/uiComponents/MuiButton';
import TradeIdeasTradeDetails from './TradeIdeasTradeDetails';
import { useState } from 'react';
import TradeIdeasBotSelection from './TradeIdeasBotSelection';
import { BotSignalParams, GetAllBots } from 'types/ApiServicesTypes';
import TradeIdeasConfirmation from './TradeIdeasConfirmation';
import usePostBotSignal from 'services/Bots/usePostBotSignal';
import { GetInstrumentsResult, Instrument } from 'types/Instrument';
import { SYMBOL } from '../utils';
import { v4 as uuid } from 'uuid';
import useGetAllBots from 'services/Bots/useGetAllBots';
// import moment from 'moment';
// import { getValueFromLocalStorage } from 'app/components/Bots/BotSignal/util';
// import { SIGNAL_LEVERAGE } from 'constants/common';

type TradeDetailsCardProps = {
  open: boolean;
  handleClose: () => void;
  tradeData: TradesType;
  trade_type: string;
  lotSize: number;
  handleLotsize: (lots: number) => void;
  symbol: string;
  expiry: string;
  instruments: GetInstrumentsResult;
};
const TradeExecutionModal = ({
  open,
  handleClose,
  tradeData,
  trade_type,
  handleLotsize,
  lotSize,
  symbol,
  expiry,
  instruments,
}: TradeDetailsCardProps) => {
  const [step, setStep] = useState(0);
  const { data: signalBots, refetch } = useGetAllBots(1, undefined, 'line');
  const [bId, setBId] = useState(0);
  const handleClick = (bot: GetAllBots) => {
    setStep(step => step + 1);
    setBId(bot.botid);
  };
  const instrumentsList = tradeData.positions.reduce((acc, curr) => {
    acc[curr.strike] = instruments.filter(
      instrument =>
        instrument.name === SYMBOL[`${symbol}`] &&
        instrument.strike_price === curr.strike &&
        instrument.instrument_type === curr.contract_type &&
        instrument.expiry === expiry &&
        instrument.exchange_ids === 'NFO',
    )[0];
    return acc;
  }, {} as Record<string, Instrument>);

  const signalsData: Array<BotSignalParams> = tradeData.positions.map(
    position => {
      return {
        signal_id: uuid(),
        instrument_id:
          instrumentsList[position.strike].instrument_id.toString(),
        symbol: instrumentsList[position.strike].trading_symbol,
        product_type: 'MIS',
        order_type: 'MARKET',
        trade_id: '234',
        transaction_type: position.direction === 'long' ? 'BUY' : 'SELL',
        order_duration: 'DAY',
        quantity: lotSize * instrumentsList[position.strike].lot_size,
        tag: '234 EN',
        position_type: 'EN',
        validity: '',
        order_price: '',
      };
    },
  );

  const mutation = usePostBotSignal(bId);
  return (
    <MuiFullScreenModal
      open={open}
      width="470px"
      handleClose={handleClose}
      body={
        <>
          {step === 0 && (
            <TradeIdeasTradeDetails
              handleLotsize={handleLotsize}
              tradeData={tradeData}
              trade_type={trade_type}
              lotSize={lotSize}
              symbol={symbol}
            />
          )}
          {step === 1 && (
            <TradeIdeasBotSelection
              signalBotsData={signalBots?.data?.result || []}
              handleClick={handleClick}
              handleCreateBot={success => {
                if (success) void refetch();
              }}
            />
          )}
          {step === 2 && (
            <TradeIdeasConfirmation
              lotSize={lotSize}
              tradeData={tradeData}
              trade_type={trade_type}
              symbol={symbol}
            />
          )}
        </>
      }
      footer={
        <Box sx={{ textAlign: 'end' }}>
          <MuiButton
            variant="contained"
            sx={{
              bgcolor: 'primary.main',
              mx: 1,
            }}
            onClick={() => {
              if (step === 0) handleClose();
              else setStep(step => step - 1);
            }}
          >
            {step === 0 ? 'Cancel' : 'Back'}
          </MuiButton>
          {step !== 1 && (
            <MuiButton
              variant="contained"
              sx={{
                bgcolor: '#007DFF',
                '&:hover': {
                  bgcolor: '#4c98fc',
                },
              }}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log('step', step);
                if (step !== 2) {
                  setStep(step => step + 1);
                } else {
                  mutation.mutate(
                    { signals: signalsData },
                    {
                      onSuccess: res => {
                        if (res.success) {
                          handleClose();
                        }
                      },
                    },
                  );
                }
              }}
            >
              {step === 0 ? 'Trade' : 'Confirm'}
            </MuiButton>
          )}
        </Box>
      }
    />
  );
};

export default TradeExecutionModal;
