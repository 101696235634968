import { OrderItemProps } from './types';
import { Box, Typography } from '@mui/material';
import { formatNumber, getDate } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { orderStatus } from '../ComponentItem/Bots/utils';
import { ChipColor } from 'types/Generic';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const OrderItem = ({ order, handleClick }: OrderItemProps) => {
  const isShowDecimalprecision = useIsFeatureFlag([
    FEATURES.IS_SHOW_DECIMAL_PRECISION,
  ]);
  return (
    <Box
      className="p-main-spacing"
      sx={{ py: 2, cursor: 'pointer' }}
      onClick={() => handleClick!(order)}
    >
      {/* {order.status && order.status !== '3' && orderStatus[order.status] && (
        <Box sx={{ mb: 1 }}>
          <MuiChip
            icon={<>{orderStatus[order.status].icon}</>}
            label={<>{orderStatus[order.status].value}</>}
            size="xsmallicon"
            color={orderStatus[order.status].chipColor as ChipColor}
          />
        </Box>
      )} */}
      <Box display="flex" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <Typography variant="body3" color="text.disabled">
            {getDate(order.order_date, true)}
          </Typography>
        </Box>
        {order.tag.length > 0 && (
          <Box
            sx={{ flex: 'initial', pl: 2, textAlign: 'right', lineHeight: 0 }}
          >
            <MuiChip
              label={order.tag}
              size="xsmall"
              color="greylight"
              sx={{ maxWidth: '100px' }}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body1"
            color="text.primary"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{order.trading_symbol}</span>
            <MuiChip
              label={order.direction === '0' ? 'B' : 'S'}
              color={order.direction === '0' ? 'infolight' : 'purplelight'}
              size="singleLetter"
              variant="filled"
              sx={{ ml: 1 }}
            />
          </Typography>
        </Box>
        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right' }}>
          <Typography variant="body2" color="info.main">
            {order.currency_symbol +
              formatNumber(
                order.value,
                undefined,
                isShowDecimalprecision ? 2 : 10,
                isShowDecimalprecision,
              )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body3"
            fontWeight={500}
            color={order.direction === '0' ? 'info.main' : 'purple.500'}
          >
            {order.quantity}
          </Typography>
          {orderStatus[order.status] && (
            <MuiChip
              icon={<>{orderStatus[order.status].icon}</>}
              label={<>{orderStatus[order.status].value}</>}
              size="xsmall"
              color={orderStatus[order.status].chipColor as ChipColor}
              sx={{ ml: 1 }}
            />
          )}
        </Box>
        <Box sx={{ flex: 'initial', pl: 2, textAlign: 'right' }}>
          <Typography variant="body3" color="text.primary">
            {order.currency_symbol +
              formatNumber(
                order.price,
                undefined,
                isShowDecimalprecision ? 2 : 10,
                isShowDecimalprecision,
              )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderItem;
