import { Box, Typography } from '@mui/material';
import MuiFullScreenModal from 'app/design/uiComponents/MuiFullScreenModal';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useGetMTMData from 'services/Bots/useGetMTMData';
import { MTMDataType } from 'types/ApiServicesTypes';

type BotMTMGraphModalProps = {
  botId: string;
  botName: string;
  open: boolean;
  handleClose: () => void;
};

const BotMTMGraphModal = ({
  botId,
  botName,
  open,
  handleClose,
}: BotMTMGraphModalProps) => {
  const lineColor = [
    '#82ca9d',
    '#8884d8',
    '#88CCEE',
    '#CC6677',
    '#DDCC77',
    '#117733',
    '#332288',
    '#AA4499',
    '#44AA99',
    '#999933',
    '#882255',
    '#661100',
    '#6699CC',
    '#888888',
  ];
  const { data: dummyData } = useGetMTMData(open, Number(botId));

  const { data, sets } = convertMTMGraphData(dummyData?.data);
  return (
    <MuiFullScreenModal
      open={open}
      width="930px"
      handleClose={handleClose}
      body={
        <Box width={'100%'} height={'700px'}>
          <Typography textAlign={'center'} variant="h5">
            {botName} MTM Graph (
            {new Date().toLocaleDateString([], {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
            )
          </Typography>
          <ResponsiveContainer width="100%" height="95%">
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid />
              <XAxis dataKey="DateTime" />
              <YAxis />
              <Tooltip />
              <Legend />
              {sets.map((set, index) => {
                return (
                  set !== 'DateTime' && (
                    <Line
                      isAnimationActive={false}
                      key={set}
                      type="monotone"
                      dataKey={`${set}`}
                      dot={false}
                      stroke={lineColor[index - 1]}
                      strokeWidth={set === 'strategy' ? 2 : 1}
                    />
                  )
                );
              })}
              {/* <Line type="monotone" dataKey="s1" stroke="#82ca9d" />
              <Line
                type="monotone"
                dataKey="s2"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              /> */}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      }
      footer={<></>}
    />
  );
};

export default BotMTMGraphModal;

const convertMTMGraphData = (data: Array<MTMDataType> | undefined) => {
  let sets = [] as string[];
  return {
    data: data?.map(point => {
      sets = [
        ...sets,
        ...Object.keys(point.Data).filter(set_name => !sets.includes(set_name)),
      ];
      return {
        DateTime: new Date(point.DateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
        ...point.Data,
      };
    }),
    sets: sets,
  };
};
