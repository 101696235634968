import PageHeader from 'app/design/speedBot/PageHeader';
import { TradesType } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { isInfinite } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { SYMBOL } from '../utils';

type TradeIdeasTradeDetailsProps = {
  tradeData: TradesType;
  trade_type: string;
  lotSize: number;
  handleLotsize: (lots: number) => void;
  symbol: string;
};

const TradeIdeasTradeDetails = ({
  tradeData,
  trade_type,
  lotSize,
  handleLotsize,
  symbol,
}: TradeIdeasTradeDetailsProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <PageHeader
        variant="withSubtitle"
        subtitle={tradeData.strategy_type}
        showMdUp={true}
        title={`${SYMBOL[`${symbol}`]}(${trade_type})`}
        {...(isMdUp && { hideBackButton: true })}
      />
      <Box className="p-main-spacing">
        <Box
          sx={{
            bgcolor: 'grey.100',
            borderRadius: 2,
            p: 1,
          }}
        >
          <Typography variant="body2">Trade Detail</Typography>
          {tradeData.positions.map((position, index) => (
            <Box
              key={index}
              display={'flex'}
              justifyContent={'space-between'}
              py={1}
            >
              <Typography variant="body2">
                {position.strike} {position.contract_type.toUpperCase()}
              </Typography>
              <MuiChip
                color={`${
                  position.direction === 'long' ? 'successlight' : 'errorlight'
                }`}
                variant="filled"
                label={position.direction === 'long' ? 'Buy' : 'Sell'}
                sx={{ ml: 1 }}
                size="small"
              />
            </Box>
          ))}
        </Box>
        <Box sx={{ py: 2 }}>
          <Box display={'flex'} justifyContent={'space-between'} py={1}>
            <Typography variant="body2" color="text.secondary">
              Max Profit
            </Typography>
            <Typography variant="body2" fontWeight={500} color={'success.main'}>
              {isInfinite(tradeData.payoff.max_gain * lotSize)
                ? 'Unlimited'
                : tradeData.payoff.max_gain * lotSize}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} py={1}>
            <Typography variant="body2" color="text.secondary">
              Max Loss
            </Typography>
            <Typography variant="body2" fontWeight={500} color={'error.main'}>
              {isInfinite(tradeData.payoff.max_loss * lotSize)
                ? '-Unlimited'
                : tradeData.payoff.max_loss * lotSize}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'} py={1}>
            <Typography variant="body2" color="text.secondary">
              Breakeven
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {tradeData.payoff.breakevens.map(
                (breakeven, index) =>
                  `${breakeven}${
                    tradeData.payoff.breakevens.length - 1 !== index ? ',' : ''
                  }`,
              )}
            </Typography>
          </Box>
          {!(
            isInfinite(tradeData.payoff.max_gain * lotSize) ||
            isInfinite(tradeData.payoff.max_loss * lotSize)
          ) && (
            <>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Alpha
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {tradeData.payoff.alpha}%
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  EV
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {tradeData.payoff.ev}
                </Typography>
              </Box>
            </>
          )}
          <Box display={'flex'} justifyContent={'space-between'} py={1}>
            <Typography variant="body2" color="text.secondary">
              Lots
            </Typography>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <IconButton
                  sx={{
                    borderRadius: 1,
                    border: '1px solid grey',
                    mr: 1,
                    py: '3px',
                    px: '8px',
                  }}
                  onClick={() => {
                    handleLotsize(lotSize === 1 ? 1 : lotSize - 1);
                  }}
                >
                  <Typography>-</Typography>
                </IconButton>
                <TextField
                  variant="outlined"
                  value={String(lotSize) || '1'}
                  type="number"
                  onChange={e =>
                    handleLotsize(
                      Number(e.target.value) === 0 ? 1 : Number(e.target.value),
                    )
                  }
                  size="small"
                  sx={{
                    width: '25%',
                  }}
                />
                <IconButton
                  sx={{
                    borderRadius: 1,
                    border: '1px solid grey',
                    ml: 1,
                    py: '3px',
                    px: '8px',
                  }}
                  onClick={() => {
                    handleLotsize(lotSize + 1);
                  }}
                >
                  <Typography>+</Typography>
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TradeIdeasTradeDetails;
