import moment from 'moment';
import { GetBotPositionsResult, Position } from 'types/ApiServicesTypes';
import { getPlatform } from './auth';
import { getCookie } from 'typescript-cookie';
import { SESSION_ID, USER_ID } from 'constants/common';

export function formatNumber(
  num: number,
  withPostfix?: boolean,
  decimalPoint?: number,
  decimalPrecision = true, // dont parse when decimal precision is true
  wantToFix = false,
) {
  if (isNaN(num)) num = 0;

  const { amount, postfix } = numberPostfix(Math.abs(num));

  let number = decimalPrecision
    ? Math.abs(withPostfix ? amount : num)
        .toFixed(decimalPoint! >= 0 ? decimalPoint : 2)
        .toString()
        .trim()
    : wantToFix
    ? Math.abs(num).toFixed(2).toString().trim()
    : Math.abs(num).toString().trim();
  let afterPoint = '';

  if (number.indexOf('.') > 0) {
    afterPoint = number.substring(number.indexOf('.'), number.length);
    // : Number.parseFloat(
    //     number.substring(number.indexOf('.'), number.length),
    //   ).toString();
  }
  number = Math.floor(parseInt(number)).toString();
  let lastThree = number.substring(number.length - 3);
  const otherNumbers = number.substring(0, number.length - 3);

  if (otherNumbers !== '') lastThree = ',' + lastThree;
  const res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;

  return `${res}${withPostfix ? postfix : ''}`;
  // US Format
  // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberPostfix(num: number): {
  amount: number;
  postfix: string;
} {
  if (isNaN(num)) return { amount: 0, postfix: '' };

  if (num > 0) {
    if (num < 1000) return { amount: num, postfix: '' };
    if (num < 100000) return { amount: num / 1000, postfix: 'K' };
    if (num < 10000000) return { amount: num / 100000, postfix: 'L' };
    if (num < 1000000000) return { amount: num / 10000000, postfix: 'Cr' };
  } else {
    if (num <= -10000000) return { amount: num / 10000000, postfix: 'Cr' };
    if (num <= -100000) return { amount: num / 100000, postfix: 'L' };
    if (num <= -1000) return { amount: num / 1000, postfix: 'K' };
    return { amount: num, postfix: '' };
  }
  return { amount: 0, postfix: '' };
}

export function calculateDuration(num: number) {
  if (num <= 31) {
    return `${num} ${num > 1 ? ' days' : ' day'}`;
  } else if (num < 366 && num >= 31) {
    if (parseInt((num / 30).toString()) === 12) {
      return '1 Year';
    } else {
      return `${parseInt((num / 30).toString())} ${(parseInt(
        (num / 30).toString(),
      ) > 1
        ? ' Months'
        : 'Month'
      ).toString()}`;
    }
  } else if (num > 366) {
    const number = num / 365;
    if (number % 1 !== 0) {
      const point = number.toString().split('.')[1];
      if (
        parseInt(point.slice(0, 2)) <= 0 &&
        parseInt(point.slice(0, 2)) <= 12
      ) {
        return `${parseInt(number.toString())} ${(parseInt(number.toString()) >
        1
          ? ' Years'
          : 'Year'
        ).toString()}`;
      } else if (
        parseInt(point.slice(0, 2)) >= 12 &&
        parseInt(point.slice(0, 2)) <= 365
      ) {
        return `${parseInt(number.toString())} ${(parseInt(number.toString()) >
        1
          ? ' Years '
          : 'Year '
        ).toString()} ${parseInt(point.slice(0, 1))} ${(parseInt(
          point.slice(0, 1),
        ) > 1
          ? ' Months'
          : ' Month'
        ).toString()}`;
      }
    } else {
      return `${number} ${number > 1 ? ' Years' : ' Year'}`;
    }
  }
}

export function tradeDuration(num: number) {
  const number = num.toString().split('.')[1];
  let day = num.toString().split('.')[0];
  if (number && day) {
    if (number.includes(':')) {
      day = day + 'd ';
      const hour = number.split(':')[0] + 'h ';
      const min = number.split(':')[1] + 'm ';

      if (parseInt(day) === 0) {
        return hour + min;
      } else if (parseInt(min) === 0) {
        return day + hour;
      } else if (parseInt(hour) === 0) {
        return day + min;
      } else {
        return day + hour + min;
      }
    } else {
      return num;
    }
  } else {
    return num;
  }
}

export function getFormattedDate(input: string) {
  const pattern = /(.*?)\/(.*?)\/(.*?)$/;
  const result = input.replace(pattern, function (match, p1, p2, p3) {
    return `${p2 < 10 ? `0${p2}` : p2} ${p1} ${p3}`;
  });
  return result;
}

export function changeDateFormat(input: string, time = '') {
  if (time === 'time') {
    return moment
      .utc(input, 'YYYYMMDD hh:mm')
      .local()
      .format('DD/MM/YYYY HH:mm');
  } else {
    return moment(getFormattedDate(input)).format('DD/MM/YYYY');
  }
}

export const groupBy = (
  data: Position[] & GetBotPositionsResult,
  field: string,
  isDate: boolean,
):
  | { key: string; data: Position[] | GetBotPositionsResult }[]
  | (Position[] | GetBotPositionsResult) => {
  if (!data || !field) return data;
  const groups = data.reduce((groups, obj) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const key = isDate
      ? (obj[field] as string).split(' ')[0]
      : (obj[field] as string);
    if (!groups[key]) {
      groups[key] = [];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    groups[key].push(obj);
    return groups;
  }, {});
  const groupArrays = Object.keys(groups).map(key => {
    return {
      key,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data: groups[key],
    };
  });
  return groupArrays;
};

class GroupByGenericProps<T> {
  key: string | number;
  members: T[] = [];
  constructor(key: string | number) {
    this.key = key;
  }
}
export function groupByGeneric<T>(
  list: T[],
  func: (x: T) => string | number,
): GroupByGenericProps<T>[] {
  const res: GroupByGenericProps<T>[] = [];
  let group: GroupByGenericProps<T> | null = null;
  list.forEach(o => {
    const groupName = func(o);
    if (group === null) {
      group = new GroupByGenericProps<T>(groupName);
    }
    if (groupName != group.key) {
      res.push(group);
      group = new GroupByGenericProps<T>(groupName);
    }
    group.members.push(o);
  });
  if (group != null) {
    res.push(group);
  }
  return res;
}

export const convertStringToDate = (
  dateString: string,
  format: string,
): Date => {
  return moment.utc(dateString, format).toDate();
};

export const formatStringToDateString = (
  dateString: string,
  dateStringFormat: string,
  format: string,
): string => {
  return moment.utc(dateString, dateStringFormat).local().format(format);
};

export const formatDateString = (
  dateString: string | Date,
  format: string,
): string => {
  return moment.utc(dateString).local().format(format);
};

export const formatDateStringToFromNow = (dateString: string): string => {
  return moment.utc(dateString).local().fromNow();
};

export const getDate = (
  dateString: string | Date,
  withTime: boolean,
): string => {
  const format = `DD MMM YYYY${withTime ? ', hh:mm:ss a' : ''}`;
  return moment.utc(dateString).local().format(format);
};

export const getTime = (dateString: string | Date): string => {
  const format = 'hh:mm:ss a';
  return moment.utc(dateString).local().format(format);
};

export const convertTimeTo12H = (dateString: string | Date): string => {
  const format = 'hh:mm a';
  return moment.utc(`0001-01-01 ${dateString}`).format(format);
};

export const getDateDifference = (
  fromDate: string | Date,
  toDate: string | Date,
  unit: moment.unitOfTime.Diff | undefined,
): number => {
  const dtFrom = moment(fromDate);
  const dtTo = moment(toDate);
  const diff = Math.abs(dtFrom.diff(dtTo, unit, true));
  return parseFloat(diff.toFixed(2));
};

export const decimalFormat = (num: number): string => {
  if (isNaN(num)) num = 0;
  return num.toFixed(2);
};

export const getAmountUnit = (amount: number): string => {
  amount = Math.abs(amount);
  if (amount >= 10000000) return 'Cr';
  if (amount >= 100000) return 'L';
  if (amount >= 1000) return 'K';
  return '';
};

export const calculateAmountUnitWise = (
  amount: number,
  unit: string,
): number => {
  if (unit === 'Cr') {
    return amount / 10000000;
  }
  if (unit === 'L') {
    return amount / 100000;
  }
  if (unit === 'K') {
    return amount / 1000;
  }
  return amount;
};

export const replaceAllChars = (
  str: string,
  key: string,
  replace: string,
): string => {
  return str.split(key).join(replace);
};

export const encodeUrlData = (str: string): string => {
  if (!str) return '';
  str = str.split('%').join('~');
  return encodeURIComponent(str);
};
export const decodeUrlData = (str: string): string => {
  if (!str) return '';
  str = decodeURIComponent(str);
  return str.split('~').join('%');
};
export const ifFutureDate = (date: string): string => {
  if (!date) return '';

  if (moment(date).diff(moment()) > 0) {
    return 'Current';
  } else {
    return date;
  }
};
export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export const getDateWithToday = (dateString: string | Date): string => {
  if (!dateString) return '';

  // Check date is today or future
  if (
    moment(moment().format('YYYYY-MM-DD')).diff(moment(dateString), 'days') <= 0
  )
    return 'Today';

  const format = `DD MMM YYYY`;
  return moment.utc(dateString).local().format(format);
};

export const getDuration = (startDate: string, endDate: string): string => {
  let durationStr = '';

  if (startDate && endDate) {
    const a = moment(endDate);
    a.add(1, 'days');
    const b = moment(startDate);

    const years = a.diff(b, 'year');
    b.add(years, 'years');

    const months = a.diff(b, 'months');
    b.add(months, 'months');

    const days = a.diff(b, 'days');

    if (years > 0) {
      durationStr =
        durationStr + (years === 1 ? `${years} year ` : `${years} years `);
    }

    if (months > 0) {
      durationStr =
        durationStr + (months === 1 ? `${months} month ` : `${months} months `);
    }

    if (days > 0) {
      durationStr =
        durationStr + (days == 1 ? `${days} day ` : `${days} days `);
    }
  }
  return durationStr;
};

export const getColorByNum = (num: number): string => {
  return num >= 0.005
    ? 'success.main'
    : num <= -0.005
    ? 'error.main'
    : 'text.primary';
};

export const getArrowDirByNum = (num: number): string => {
  return num === 0 ? '' : num > 0 ? '▲' : '▼';
};

export const getFeesColor = () => 'text.primary';

export const parseFormattedPhoneNumber = (
  formattedNumber: string,
  countryCode: string,
) => {
  const regex = new RegExp(`\\+${countryCode}\\s*(\\(|\\d.*)*`, 'g');
  const result = regex.exec(replaceAllChars(formattedNumber, ' ', ''));
  const phoneString = result instanceof Array ? result[1] : '';
  const phone = phoneString ? phoneString.replace(/\(|\)|\s|-/g, '') : '';

  return {
    countryCode,
    phone,
  };
};

export const getFullDomain = () => {
  // return location.protocol + '//' + location.host;
  return location.origin;
};

export const trimChars = (str: string, c: string) => {
  const re = new RegExp('^[' + c + ']+|[' + c + ']+$', 'g');
  return str.replace(re, '');
};

export const getAnnualReturn = (
  start_date: string,
  end_date: string,
  net_profit_percentage: number,
) => {
  const diffYears = getDateDifference(
    start_date,
    end_date ? end_date : formatDateString(new Date(), 'YYYY-MM-DD'),
    'years',
  );
  const annualReturn = diffYears > 0 ? net_profit_percentage / diffYears : 0;

  return annualReturn;
};

export const getQueryParam = (key: string, defaultValue?: string) => {
  let value = defaultValue ? defaultValue : '';
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has(key)) {
    value = queryParams.get(key) as string;
  }
  return value;
};

export const getQueryParams = (keys: string[]) => {
  const params: { [key: string]: string } = {};
  const queryParams = new URLSearchParams(location.search);
  keys.forEach(key => {
    if (queryParams.has(key)) {
      params[key] = queryParams.get(key) as string;
    }
  });
  return params;
};

// export const trimChar = (str: string, delimiter: string) => {
//   const pattern = `[^\\${delimiter}]`;
//   const start = str.search(pattern);
//   const stop = str.length - str.split('').reverse().join('').search(pattern);
//   return str.substring(start, stop);
// };

export const openInNewTab = (url: string, data?: Record<string, string>) => {
  // To handle opening url in safari for IOS
  if (getPlatform() === 'ios' && window.ReactNativeWebView) {
    let externalLink = url;
    if (url.startsWith('/'))
      externalLink =
        window.location.origin +
        url +
        `?${SESSION_ID}=${getCookie(SESSION_ID)}&${USER_ID}=${getCookie(
          USER_ID,
        )}`;
    if (data && Object.keys(data).length > 0) {
      const params = new URLSearchParams(data);
      externalLink += `${url.startsWith('/') ? '&' : '?'}${params.toString()}`;
    }
    window.ReactNativeWebView.postMessage(`openLink::${externalLink}`);
  } else window.open(url, '_blank', 'noopener,noreferrer');
};

export function GetUniqueItemsGeneric<T>(list: T[]): T[] {
  list = list.filter((x, i, a) => a.indexOf(x) === i);
  return list;
}

export function CheckUniqueItemsByPropGeneric<T>(
  list: T[],
  prop: string,
): boolean {
  let newList = list.map(x => x[prop] as string);
  newList = newList.filter((x, i, a) => a.indexOf(x) === i);
  return newList.length === list.length;
}

export const isBlankHtmlString = (str: string) => {
  return !str || str === '<p></p>' || str === '<p><br></p>';
};

export const getFutureNextDayDate = (day: string, isNextweek?: boolean) => {
  const dt = moment().hour(0).minute(0).second(0);
  let dayOfWeek = moment().day(day.toLowerCase()).hour(0).minute(0).second(0);
  if (dayOfWeek < dt) dayOfWeek = dayOfWeek.add(1, 'weeks');
  if (isNextweek) dayOfWeek = dayOfWeek.add(1, 'weeks');
  return dayOfWeek.format('YYYY-MM-DD');
};

export const checkIsMultipleOf = (value: number, lotSize: number) => {
  const valDecCount = (value.toString().split('.')[1] || '').length;
  const lotSizeDecCount = (lotSize.toString().split('.')[1] || '').length;
  const decCount = Math.max(valDecCount, lotSizeDecCount);
  const multiplier = 10 ** decCount;

  const valInt = Math.round(value * multiplier);
  const stepInt = Math.round(lotSize * multiplier);
  return (valInt % stepInt) / multiplier == 0;
};

export const getLastFridayDate = () => {
  const today = moment();
  const lastFriday = today.clone().day(-2);

  return lastFriday;
};
export const getStartEndDate = (
  duration,
): { start_date: string; end_date: string } => {
  let start_date: moment.Moment;
  const end_date = getLastFridayDate();

  switch (duration) {
    case 'Last 1 Month':
      start_date = moment(getLastFridayDate()).subtract(1, 'months');
      break;
    case 'Last 3 Months':
      start_date = moment().subtract(3, 'months');
      break;
    case 'Last 6 Months':
      start_date = moment().subtract(6, 'months');
      break;
    case 'Last 1 Year':
      start_date = moment().subtract(1, 'years');
      break;
    default:
      start_date = moment();
  }

  return {
    start_date: start_date.format('YYYY-MM-DD'),
    end_date: end_date.format('YYYY-MM-DD'),
  };
};
export const getStartDateFromDuration = (duration: string) => {
  const dateData = duration.toLocaleLowerCase().split(' ');

  if (dateData[2] === 'months' || dateData[2] === 'month') {
    return moment().subtract(dateData[1], 'months').format('YYYY-MM-DD');
  } else if (dateData[2] === 'year' || dateData[2] === 'years') {
    return moment().subtract(dateData[1], 'years').format('YYYY-MM-DD');
  }
};

export const getGreetings = () => {
  const myDate = new Date();
  const hours = myDate.getHours();
  let greet;

  if (hours < 12) greet = 'Morning';
  else if (hours >= 12 && hours <= 17) greet = 'Afternoon';
  else if (hours >= 17 && hours <= 24) greet = 'Evening';

  return `Good ${greet},`;
};
export function formatTimeForIndicatorTextView(time: string) {
  const duration = moment.duration(time);
  const hours = duration.hours();
  const minutes = duration.minutes();
  if (Number(hours) > 0) {
    return String(hours) + 'h';
  } else if (Number(minutes) > 0) {
    return String(minutes) + 'm';
  } else {
    return '0m';
  }
}
export const downloadWithCustomFileName = async (
  url: string,
  filename: string,
) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlBlob;
    // DD-MON-YYYY Order Sheet (AASMA-TAP)
    a.download = filename; // Set your custom filename here
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There has been a problem with your fetch operation:', error);
  }
};

export function isInfinite(num: number): boolean {
  return Math.abs(num) >= 10000000000;
}
export const getFirstSecondLetterfromName = (name: string) => {
  const splitName = name.split(' ');
  let letter = '';
  if (splitName.length > 1) {
    const firstLetter = splitName[0].charAt(0).toUpperCase();
    const secondLetter = splitName[1].charAt(0).toUpperCase();
    return (letter += firstLetter + secondLetter);
  } else {
    letter =
      splitName[0].charAt(0).toUpperCase() +
      splitName[0].charAt(1).toUpperCase();
  }
  return letter;
};
export function filterArrayWithKey<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  K extends keyof T,
>(data: T[], key: K, value: T[K]): T[] {
  return data.filter(item => item[key] === value);
}
export function getOSFromWeb() {
  let name = '';
  if (navigator.userAgent.indexOf('Win') != -1) name += 'Windows OS';
  if (navigator.userAgent.indexOf('Mac') != -1) name += 'MacOS';
  if (navigator.userAgent.indexOf('X11') != -1) name += 'UNIX OS';
  if (navigator.userAgent.indexOf('Linux') != -1) name += 'Linux OS';
  return name;
}
export const toBoolean = (str: string): string | boolean =>
  str === 'true' ? true : str === 'false' ? false : str;
