import { Box, Divider, Typography } from '@mui/material';
import ManualBotModal from 'app/components/Portfolio/ManualBotModal';
import PageHeader from 'app/design/speedBot/PageHeader';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useState } from 'react';
import { GetAllBots } from 'types/ApiServicesTypes';

type TradeIdeasBotSelectionProps = {
  signalBotsData: Array<GetAllBots>;
  handleClick: (selectedBot: GetAllBots) => void;
  handleCreateBot: (success: boolean) => void;
};

const TradeIdeasBotSelection = ({
  signalBotsData,
  handleClick,
  handleCreateBot,
}: TradeIdeasBotSelectionProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <PageHeader
        variant="default"
        title={`Select Bots`}
        {...(isMdUp && { hideBackButton: true })}
      />
      {signalBotsData.length > 0 ? (
        signalBotsData.map(bot => (
          <Box
            key={bot.botid}
            className="p-main-spacing"
            sx={{ cursor: 'pointer', my: 1 }}
            onClick={() => handleClick(bot)}
          >
            <Typography variant="body1">{bot.name}</Typography>
            <Typography variant="body3">{bot.category}</Typography>
            <Divider />
          </Box>
        ))
      ) : (
        <Box
          className="p-main-spacing d-flex flex-column"
          sx={{ margin: 4, marginTop: 0, justifyContent: 'center' }}
        >
          <Typography>
            You do not have a bot to execute this trade, so you should create
            one first.
          </Typography>
          <MuiButton
            variant="contained"
            sx={{
              bgcolor: 'secondary.default',
              width: '100%',
            }}
            onClick={() => setOpen(true)}
          >
            + create
          </MuiButton>
          <ManualBotModal
            open={open}
            handleClose={handleClose}
            handleClick={handleCreateBot}
          />
        </Box>
      )}
    </Box>
  );
};

export default TradeIdeasBotSelection;
