import { Box } from '@mui/material';
import OptionTab from './OptionTab/OptionTab';
import { useCallback, useState } from 'react';
import ContentTabs from 'app/design/itemsComponent/ContentTabs';
import { useTranslation } from 'react-i18next';
import PositionTab from './PositionTab/PositionTab';
import {
  GetOptionChainResultDataType,
  SymbolMetadata,
} from 'types/ApiServicesTypes';
import {
  OptionChainWithExpiryType,
  OptionTabMetaData,
} from 'app/pages/Simulator';
import {
  OptionTableConfig,
  PositionData,
  PositionMetaData,
  PositionWithExpiryData,
} from './types';

const OptionChainContent = ({
  date,
  optionTabMetaData,
  setOptionTabMetaData,
  optionChainData,
  optionChainMetadata,
  optionChainCurrentExpiryData,
  lotSize,
  positions,
  setPositions,
}: {
  date: string;
  optionTabMetaData: OptionTabMetaData;
  setOptionTabMetaData: (option: OptionTabMetaData) => void;
  optionChainData: OptionChainWithExpiryType;
  optionChainCurrentExpiryData: GetOptionChainResultDataType | undefined;
  optionChainMetadata: SymbolMetadata | undefined;
  lotSize: number;
  positions: PositionWithExpiryData;
  setPositions: (positions: PositionWithExpiryData) => void;
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const handleChange = (index: number) => {
    setSelectedTab(index);
  };
  const [tableConfig, setTableConfig] = useState<OptionTableConfig>({
    isGreek: false,
    isCE: true,
  });
  const tabs = [
    {
      label: t('simulator.option_chain'),
    },
    {
      label: t('simulator.positions'),
    },
  ];

  const handleCreatPosition = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (positionAddedData: Record<string, PositionData>, expiry: string) => {
      setPositions({ ...positions, ...positionAddedData });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUpdatePositions = (position: PositionMetaData) => {
    if (position.expiryDate) {
      if (position.contractType === 'fut') {
        setPositions({
          ...positions,
          [position.expiryDate]: {
            ...positions[position.expiryDate],
            [`FUT_${position.strike}`]: position,
          },
        });
      } else if (position.contractType === 'ce') {
        setPositions({
          ...positions,
          [position.expiryDate]: {
            ...positions[position.expiryDate],
            [`CE_${position.strike}`]: position,
          },
        });
      } else {
        setPositions({
          ...positions,
          [position.expiryDate]: {
            ...positions[position.expiryDate],
            [`PE_${position.strike}`]: position,
          },
        });
      }
    }
  };

  const removeAllPositions = () => {
    setPositions({});
  };

  const removePosition = (position: PositionMetaData) => {
    delete positions[position.expiryDate][
      `${position.contractType.toUpperCase()}_${position.strike}`
    ];
    setPositions({ ...positions });
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      border={'1px solid rgb(0 0 0 / 23%)'}
      borderRadius={'1em'}
      flex={1}
      height={'100'}
    >
      <Box position={'relative'} mb={1}>
        <ContentTabs
          value={selectedTab}
          onChange={(e, index) => handleChange(index)}
          tabs={tabs}
          noPadding
        />
      </Box>
      {selectedTab === 0 && (
        <OptionTab
          date={date}
          handleOptionClick={handleCreatPosition}
          positionsWithExpiry={positions}
          optionChainData={optionChainCurrentExpiryData}
          optionTabMetaData={optionTabMetaData}
          setOptionTabMetaData={setOptionTabMetaData}
          optionChainMetadata={optionChainMetadata}
          removeAllPositions={removeAllPositions}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
        />
      )}
      {selectedTab === 1 && (
        <PositionTab
          positionsWithExpiry={positions}
          optionChainData={optionChainData}
          lotSize={lotSize}
          currentExpiryDate={optionTabMetaData.optionExpiry}
          date={date}
          updatePositions={handleUpdatePositions}
          removePosition={removePosition}
        />
      )}
    </Box>
  );
};

export default OptionChainContent;
