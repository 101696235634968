import PageHeader from 'app/design/speedBot/PageHeader';
import { TradesType } from '../types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { Box, Divider, Typography } from '@mui/material';
import { SYMBOL } from '../utils';
import MuiChip from 'app/design/uiComponents/MuiChip';

type TradeIdeasConfirmationProps = {
  tradeData: TradesType;
  trade_type?: string;
  lotSize: number;
  symbol: string;
};

const TradeIdeasConfirmation = ({
  tradeData,
  lotSize,
  symbol,
}: TradeIdeasConfirmationProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  return (
    <>
      <PageHeader
        variant="default"
        title={`Confirm Your Trades`}
        {...(isMdUp && { hideBackButton: true })}
      />
      <Box className="p-main-spacing">
        {tradeData.positions.map(position => {
          return (
            <Box key={position.strike} sx={{ my: 2 }}>
              <Typography sx={{ my: 1 }}>
                {`${SYMBOL[`${symbol}`]}`} {tradeData.symbol}
                {position.strike} {position.contract_type.toUpperCase()}
                <MuiChip
                  color={`${
                    position.direction === 'long'
                      ? 'successlight'
                      : 'errorlight'
                  }`}
                  variant="filled"
                  label={position.direction === 'long' ? 'Buy' : 'Sell'}
                  sx={{ ml: 1 }}
                  size="small"
                />
              </Typography>
              <Divider />
            </Box>
          );
        })}
        <Box sx={{ my: 2 }}>
          <Typography sx={{ my: 1 }}>Lots : {lotSize}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default TradeIdeasConfirmation;
