import { useQuery } from 'react-query';
import { GetBrokers } from 'services/apiServices';
import { Errors, GetBrokersResult } from 'types/ApiServicesTypes';
import { isUserLoggedIn } from 'utils/auth';

export default function useGetBrokers(params?: string) {
  const isLoggedIn = isUserLoggedIn();
  const objQuery = useQuery<GetBrokersResult, Errors>(
    ['GetBrokers', params],
    () => GetBrokers(params),
    { enabled: !!isLoggedIn },
  );
  return objQuery;
}
