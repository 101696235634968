import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import CircularLoader from 'app/design/uiComponents/CircularLoader';
import BotDetailPageHeader from 'app/components/Bots/BotDetail/BotDetailPageHeader';
import BotStatusAction from 'app/components/Bots/BotDetail/BotStatusAction';
import BotDetailLinks from 'app/components/Bots/BotDetail/BotDetailLinks';
import BotStopAction from 'app/components/Bots/BotDetail/BotStopAction';
// import BotDetailAttributes from 'app/components/Bots/BotDetail/BotDetailAttributes';
import BotDetailDescription from 'app/components/Bots/BotDetail/BotDetailDescription';

// Service
import useGetBotDetails from 'services/Bots/useGetBotDetails';
import BotArchiveAction from 'app/components/Bots/BotDetail/BotArchiveAction';

export function BotDetailsPage({ location }: { location: { search: string } }) {
  const { bid, sid, title } =
    useParams<{ bid: string; sid: string; title: string }>();
  const subId = isNaN(parseInt(sid)) ? 0 : parseInt(sid);

  const { isLoading } = useGetBotDetails(parseInt(bid), subId);

  return (
    <>
      <Helmet>
        <title>Bots Detail</title>
        <meta name="description" content="Bots Detail Page" />
      </Helmet>

      <CircularLoader open={isLoading} />
      <BotDetailPageHeader />
      <BotDetailDescription />
      {/* <BotDetailAttributes /> */}
      <BotStatusAction search={location.search} />
      <BotDetailLinks />
      <Box
        className="m-main-spacing"
        display="flex"
        justifyContent="right"
        gap={2}
        flexWrap={'wrap'}
      >
        <BotArchiveAction />
        <BotStopAction
          search={location.search}
          bid={bid}
          sid={sid}
          isRestart={true}
        />
      </Box>

      {/* {data && data.name && (
        <Box sx={{ pt: 3 }} className="p-main-spacing">
          <Divider />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ my: 3 }}
          >
            <Typography variant="body2" sx={{ mb: 2 }} fontWeight={500}>
              Share on
            </Typography>
            <ShareIcons variant="small" title={data.name} />
          </Box>
        </Box>
      )} */}
    </>
  );
}
