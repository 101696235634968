import React, { Fragment, memo, useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';
import SummaryDetail from './SummaryDetail';
import { PortfolioBotType } from 'types/ComponentTypes';
import { useGetPortfolio } from 'services/Portfolio/useGetPortfolioActivePaper';
import { GetSiteInfoResult, PortfolioBotsData } from 'types/ApiServicesTypes';
import { useGetTickData } from 'app/hooks/useGetTickData';
import { calculateTodaysChange } from './utils';
import {
  BANK_NIFTY_SYMBOL,
  FIN_NIFTY_SYMBOL,
  NIFTY_SYMBOL,
} from 'constants/common';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import { SymbolsData } from 'types/Symbols';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
import { getQueryParam } from 'utils/GenericFunctions';
import { MCXSYMBOLEMULTIPLAYER } from 'types/Position';

function useCalculateTotalInitialCaptial(
  portfolioBots: PortfolioBotsData['bots'],
) {
  const totalInitialCapital = React.useMemo(() => {
    let totalCapital = 0;
    if (portfolioBots && portfolioBots.length > 0) {
      portfolioBots.map(bot => {
        totalCapital += bot.initial_capital;
      });
    }
    return totalCapital;
  }, [portfolioBots]);

  return totalInitialCapital;
}

const PortfolioSummarySkelaton = () => {
  return <Skeleton variant="rectangular" height={150} />;
};

type PortfolioSummaryUIProps = {
  portfolio_value: number;
  total_profit_loss: number;
  total_profit_loss_per: number;
  initial_capital: number;
  todays_change: number;
  todays_change_per: number;
  currencySymbol: string;
  liveFeedData: SymbolsData;
};

const PortfolioSummaryUI = (props: PortfolioSummaryUIProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  return (
    <Fragment>
      <Box
        sx={
          isMdUp
            ? {
                mt: 1,
                position: 'sticky',
                top: isSideBarView ? 0 : 60,
                zIndex: '1',
              }
            : { mt: 1 }
        }
      >
        <SummaryDetail {...props} changeOnScroll={true} />
      </Box>
    </Fragment>
  );
};

export const useCalcuatePortfolioSummaryUIData = (
  data: PortfolioBotsData,
  siteInfo?: GetSiteInfoResult,
) => {
  const {
    bots,
    symbols,
    positionData: openPositions,
    data: todaysPositions,
  } = data;
  const portfolioSymbols = useMemo(() => {
    return (
      siteInfo?.portfolio_symbols?.reduce((acc, crr) => {
        acc.push(crr.value);
        return acc;
      }, [] as string[]) || []
    );
  }, [siteInfo]);

  const symbolsWithNifty = React.useMemo(
    () =>
      symbols.concat([
        NIFTY_SYMBOL,
        BANK_NIFTY_SYMBOL,
        FIN_NIFTY_SYMBOL,
        ...portfolioSymbols,
      ]),
    [symbols, portfolioSymbols],
  );

  const liveFeedData = useGetTickData(symbolsWithNifty);
  const ptype: 'active' | 'paper' | string = getQueryParam('ptype');
  const todayClosedPosition = todaysPositions.bots[
    ptype as 'active' | 'paper'
  ]?.flatMap(item =>
    (item.bot_info?.today_positions ?? []).filter(x => x.status === 'closed'),
  );
  const todayOpenPosition = todaysPositions.bots[
    ptype as 'active' | 'paper'
  ]?.flatMap(item =>
    (item.bot_info?.today_positions ?? []).filter(x => x.status === 'open'),
  );
  let calculateTodaysRealisedProfit = 0;
  let calculateTodaysRealisedLoss = 0;
  let calculateTodaysUnrealisedProfit = 0;
  let calculateTodaysUnrealisedLoss = 0;
  todayClosedPosition &&
    todayClosedPosition.forEach(item => {
      const profitOrLoss = item.profit_loss;

      if (profitOrLoss > 0) {
        calculateTodaysRealisedProfit += profitOrLoss;
      } else if (profitOrLoss < 0) {
        calculateTodaysRealisedLoss += Math.abs(profitOrLoss);
      }
    });
  todayOpenPosition &&
    todayOpenPosition.forEach(item => {
      const currentPrice =
        liveFeedData && liveFeedData[item.trading_symbol]
          ? Number(liveFeedData[item.trading_symbol].Close)
          : 0;
      const isBuy = item.direction === '0';
      const multiplier =
        (MCXSYMBOLEMULTIPLAYER[item.instrument_id] as number) || 1;
      const profitLoss =
        currentPrice === 0
          ? item.profit_loss
          : isBuy
          ? (currentPrice - item.entry_Price) *
            Math.abs(item.quantity) *
            multiplier
          : (item.entry_Price - currentPrice) *
            Math.abs(item.quantity) *
            multiplier;
      if (profitLoss > 0) {
        calculateTodaysUnrealisedProfit += profitLoss;
      } else if (profitLoss < 0) {
        calculateTodaysUnrealisedLoss += Math.abs(profitLoss);
      }
    });
  const realisedUnrealisedData = {
    calculateTodaysRealisedProfit,
    calculateTodaysRealisedLoss,
    calculateTodaysUnrealisedProfit,
    calculateTodaysUnrealisedLoss,
  };

  const currencySymbol = bots[0]?.currency_symbol;

  const totalInitialCapital = useCalculateTotalInitialCaptial(bots);

  const todays_change = calculateTodaysChange(openPositions, liveFeedData);
  const todays_change_per =
    totalInitialCapital === 0 ? 0 : (todays_change / totalInitialCapital) * 100;
  let total_fees = 0,
    netProfit = 0,
    portfolio_value = 0,
    total_profit_loss = 0,
    total_profit_loss_per = 0,
    initial_capital = 0;
  bots.forEach(bot => {
    total_fees += bot && bot.bot_info ? Math.round(bot.bot_info.total_fees) : 0;
    netProfit += bot && bot.bot_info ? bot.bot_info.net_profit : 0;

    portfolio_value += bot && bot.bot_info ? bot.bot_info.portfolio_value : 0;
    total_profit_loss +=
      bot && bot.bot_info ? bot.bot_info.total_profit_loss : 0;
    initial_capital += bot && bot.bot_info ? bot.bot_info.initial_capital : 0;
  });

  total_profit_loss_per =
    initial_capital === 0 ? 0 : (total_profit_loss / initial_capital) * 100;

  return {
    currencySymbol,
    portfolio_value,
    total_profit_loss,
    total_profit_loss_per,
    initial_capital,
    todays_change,
    todays_change_per,
    total_fees,
    netProfit,
    liveFeedData,
    realisedUnrealisedData,
  };
};

const PortfolioSummary = ({ type }: { type: PortfolioBotType }) => {
  const siteinfo = useGetSiteInfo();
  const query = useGetPortfolio();
  const data = type === 'ACTIVE' ? query.data!.active : query.data!.paper;
  const uiData = useCalcuatePortfolioSummaryUIData(data, siteinfo.data);

  if (siteinfo.isLoading || query.isLoading) {
    return <PortfolioSummarySkelaton />;
  }

  return <PortfolioSummaryUI {...uiData} />;
};

export default memo(PortfolioSummary);
