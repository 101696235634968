import { Popover, Typography, Box, Grid, Divider } from '@mui/material';
import { formatNumber, getArrowDirByNum } from 'utils/GenericFunctions';
interface IRealisedPopoverProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  realizedProfit: number;
  realizedLoss: number;
  unrealizedProfit: number;
  unrealizedLoss: number;
  id?: string;
  currencySymbol?: string;
}
const RealisedPopover = ({
  open,
  anchorEl,
  handleClose,
  realizedLoss,
  realizedProfit,
  unrealizedLoss,
  unrealizedProfit,
  id,
  currencySymbol,
}: IRealisedPopoverProps) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        sx: { padding: 2, minWidth: 250, borderRadius: 2 },
      }}
    >
      <Typography variant="h6" gutterBottom>
        P&L Summary
      </Typography>

      {/* Realized Section */}
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Realized
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body2" color="green">
              Profit:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="green" align="right">
              {`${getArrowDirByNum(
                realizedProfit,
              )} ${currencySymbol}${formatNumber(realizedProfit, false, 0)}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="red">
              Loss:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="red" align="right">
              {`${getArrowDirByNum(
                realizedLoss,
              )} ${currencySymbol}${formatNumber(realizedLoss, false, 0)}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 1 }} />

      {/* Unrealized Section */}
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Unrealized
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body2" color="green">
              Profit:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="green" align="right">
              {`${getArrowDirByNum(
                unrealizedProfit,
              )} ${currencySymbol}${formatNumber(unrealizedProfit, false, 0)}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="red">
              Loss:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="red" align="right">
              {`${getArrowDirByNum(
                unrealizedLoss,
              )} ${currencySymbol}${formatNumber(unrealizedLoss, false, 0)}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default RealisedPopover;
