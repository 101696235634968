import { memo, useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  //  IconButton,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import {
  decimalFormat,
  formatNumber,
  getColorByNum,
  getArrowDirByNum,
  getFeesColor,
  filterArrayWithKey,
  // getQueryParam,
} from 'utils/GenericFunctions';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { SymbolsData } from 'types/Symbols';
import useGetSiteInfo from 'services/common/useGetSiteInfo';
import PortfolioSymbolItem from './PortfolioSymbolItem';
import {
  FeatureFlag,
  useIsFeatureFlag,
  // useIsFeatureFlag,
} from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';
// import SocialIcon from 'app/components/Menu/SocialIcon';
// import { IosShare } from '@mui/icons-material';
import SocialShareModal from 'app/components/Portfolio/SocialShareModal';
import usePostPortfolioShare from 'services/Portfolio/usePostPortfolioShare';
import moment from 'moment';
import HintAlert from '../Bots/HintAlert';
import { deepOrange } from '@mui/material/colors';
import { InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';
import useGetBrokers from 'services/Menu/useGetBrokers';
import RealisedPopover from './RealisedPopover';

type SummaryDetailProps = {
  portfolio_value: number;
  total_fees?: number;
  total_profit_loss: number;
  total_profit_loss_per: number;
  initial_capital: number;
  todays_change: number;
  todays_change_per: number;
  netProfit?: number;
  started_date?: string;
  strategy?: boolean;
  currencySymbol: string;
  liveFeedData: SymbolsData;
  changeOnScroll: boolean;
  realisedUnrealisedData?: {
    calculateTodaysRealisedProfit: number;
    calculateTodaysRealisedLoss: number;
    calculateTodaysUnrealisedProfit: number;
    calculateTodaysUnrealisedLoss: number;
  };
};

const SummaryDetail = (props: SummaryDetailProps) => {
  const {
    currencySymbol,
    portfolio_value,
    total_fees,
    total_profit_loss,
    total_profit_loss_per,
    initial_capital,
    todays_change,
    todays_change_per,
    netProfit,
    started_date,
    strategy,
    liveFeedData,
    changeOnScroll,
    realisedUnrealisedData,
  } = props;
  const [socialShareModal, setSocialShareModal] = useState(false);
  const [completeURL, setCompleteURL] = useState('');
  const { data: siteInfo } = useGetSiteInfo();
  const URL = location.origin;
  const postportflioMutation = usePostPortfolioShare();
  const { data: brokersData } = useGetBrokers();
  const sendDate = moment(postportflioMutation.data?.date).format('YYYY-MM-DD');
  const isRealisedUnRealisedTooltip = useIsFeatureFlag([
    FEATURES.IS_REALISED_UNREALISED_TOOLTIP,
  ]);
  // const isSharePortfolio = useIsFeatureFlag([FEATURES.IS_SHARE_PORTFOLIO]);
  // const query = getQueryParam('ptype');
  const {
    calculateTodaysRealisedLoss = 0,
    calculateTodaysRealisedProfit = 0,
    calculateTodaysUnrealisedLoss = 0,
    calculateTodaysUnrealisedProfit = 0,
  } = realisedUnrealisedData || {};
  // const handleMutateClick = () => {
  //   postportflioMutation.mutate();
  //   // eslint-disable-next-line no-console
  //   setSocialShareModal(true);
  // };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profit-loss-popover' : undefined;

  // Example data
  const realizedProfit = calculateTodaysRealisedProfit;
  const realizedLoss = -calculateTodaysRealisedLoss;
  const unrealizedProfit = calculateTodaysUnrealisedProfit;
  const unrealizedLoss = -calculateTodaysUnrealisedLoss;
  useEffect(() => {
    setCompleteURL(
      `${URL}/portfoliopnl/share/${postportflioMutation.data?.user_id}?date=${sendDate}`,
    );
  }, [URL, postportflioMutation.data?.user_id, sendDate]);
  const [top, setTop] = useState<number | null>(null);
  const [changeStyle, setChangeStyle] = useState<boolean>(false);
  const isSideBarView = useIsFeatureFlag([FEATURES.IS_SIDEBAR_VIEW]);
  const inputRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollHandler = _ => {
    // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-call
    setTop(inputRef.current && inputRef.current.getBoundingClientRect().bottom);
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  const isMdUp = useGetMediaQueryUp('md');
  const isLgUp = useGetMediaQueryUp('lg');
  useEffect(() => {
    if (changeOnScroll && isMdUp) {
      if (top && top <= (isSideBarView ? 240 : 300)) {
        setChangeStyle(false);
      } else {
        setChangeStyle(true);
      }
    } else {
      setChangeStyle(true);
    }
  }, [top, isMdUp, changeOnScroll, isSideBarView]);
  const netProfitPer = netProfit ? (netProfit / initial_capital) * 100 : 0;
  const portfolioSymbols =
    siteInfo && siteInfo.portfolio_symbols?.slice(0, isLgUp ? 3 : 2);
  const isShowTicksDataWithoutBroker = useIsFeatureFlag([
    FEATURES.IS_SHOW_TICKS_DATA_WITHOUT_BROKER,
  ]);
  const filteredBrokersData =
    brokersData && filterArrayWithKey(brokersData, 'isVerified', true);
  const showTickMessage = isShowTicksDataWithoutBroker
    ? true
    : filteredBrokersData && filteredBrokersData.length > 0
    ? true
    : false;
  return (
    <Box ref={inputRef}>
      <Box
        className="p-main-spacing"
        sx={
          changeStyle
            ? { backgroundColor: 'grey.100', py: 2 }
            : { backgroundColor: 'grey.100', py: 2 }
        }
      >
        {!strategy && portfolioSymbols && portfolioSymbols.length > 0 && (
          <Box>
            <Box
              display={'flex'}
              alignItems="center"
              sx={{
                pb: { xs: 1, md: 0 },
                justifyContent: { xs: 'space-between', md: 'flex-end' },
              }}
            >
              {!showTickMessage && (
                <Box marginRight={'auto'}>
                  <HintAlert
                    severity="warning"
                    textColor={deepOrange['900']}
                    iconColor={deepOrange['900']}
                    hintTextKey="home.hint_text"
                    icon={
                      <WarningAmberOutlined
                        sx={{ color: deepOrange['A400'] }}
                      />
                    }
                  />
                </Box>
              )}
              {portfolioSymbols.map((symbol, i) => (
                <PortfolioSymbolItem
                  key={symbol.value}
                  liveFeedData={liveFeedData}
                  symbol={symbol}
                  isLast={i === portfolioSymbols.length - 1}
                />
              ))}
            </Box>
            <Divider sx={{ mb: { xs: 1, md: 2 }, mt: { xs: 0, md: 2 } }} />
          </Box>
        )}
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            justifyContent: { md: 'space-between' },
          }}
        >
          <Box
            display={'flex'}
            sx={
              changeStyle
                ? {
                    pb: { xs: 1, md: 0 },
                    flex: { md: 1 },
                    flexFlow: { md: 'column', lg: 'row' },
                    alignItems: { xs: 'center', md: 'flex-start' },
                    justifyContent: {
                      xs: 'space-between',
                      md: 'center',
                      lg: 'flex-start',
                    },
                  }
                : {
                    pb: { xs: 1, md: 0 },
                    width: 'auto',
                    flexFlow: { md: 'column', lg: 'row' },
                    alignItems: { xs: 'center', md: 'flex-start' },
                    justifyContent: {
                      xs: 'space-between',
                      md: 'center',
                      lg: 'flex-start',
                    },
                  }
            }
          >
            <Box
              sx={{
                display: { xs: 'contents', lg: changeStyle ? 'flex' : 'none' },
                flexFlow: { lg: 'column' },
              }}
            >
              <Typography
                variant="body3"
                color="text.secondary"
                sx={{ mt: { md: 0.5 } }}
              >
                <FormattedMessage id="summary_detail.todays_change" />
                {isRealisedUnRealisedTooltip && (
                  <IconButton onClick={handleClick}>
                    <InfoOutlined />
                  </IconButton>
                )}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                sx={{ order: { md: -1 } }}
              >
                <Typography
                  variant={isMdUp ? 'h6' : 'body2'}
                  display="inline"
                  color={getColorByNum(todays_change)}
                >
                  {`${getArrowDirByNum(
                    todays_change,
                  )} ${currencySymbol}${formatNumber(todays_change, false, 0)}`}
                </Typography>
                <Typography
                  variant="body2"
                  display="inline"
                  color={getColorByNum(todays_change)}
                  sx={{ ml: 0.5 }}
                >{`(${todays_change_per > 0 ? '+' : ''}${decimalFormat(
                  todays_change_per,
                )}%)`}</Typography>
              </Box>
            </Box>
            {strategy && isLgUp && (
              <Box
                sx={{
                  display: { xs: 'contents', lg: 'flex' },
                  flexFlow: { lg: 'column' },
                  mx: 'auto',
                }}
              >
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_detail.p_or_l" />
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ order: { md: -1 } }}
                >
                  <Typography
                    variant={'h6'}
                    display="inline"
                    color={getColorByNum(total_profit_loss)}
                  >
                    {`${getArrowDirByNum(
                      total_profit_loss,
                    )} ${currencySymbol}${formatNumber(
                      total_profit_loss,
                      false,
                      0,
                    )}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    display="inline"
                    color={getColorByNum(total_profit_loss)}
                  >{` (${total_profit_loss > 0 ? '+' : ''}${decimalFormat(
                    total_profit_loss_per,
                  )}%)`}</Typography>
                </Box>
              </Box>
            )}
          </Box>
          {changeStyle && (
            <Divider
              orientation={isMdUp ? 'vertical' : 'horizontal'}
              sx={{
                mb: { xs: 1, md: 0 },
                mx: { md: 2 },
              }}
              {...(isMdUp && { flexItem: true })}
            />
          )}
          <Box
            sx={
              changeStyle
                ? { width: { md: '327px' }, transition: 'width 0.3s linear' }
                : {
                    transform: 'translate(20px ,0px) scale(1)',
                    transition: 'transform 0.3s linear',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'flex-end',
                    flex: '2',
                  }
            }
          >
            {!changeStyle ? (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={!changeStyle ? 'column-reverse' : 'row'}
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_detail.todays_change" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'body1' : 'body2'}
                  color={getColorByNum(todays_change)}
                >
                  {`${getArrowDirByNum(
                    todays_change,
                  )} ${currencySymbol}${formatNumber(
                    todays_change,
                    false,
                    0,
                  )} (${decimalFormat(todays_change_per)}%)`}
                </Typography>
              </Box>
            ) : (
              <> </>
            )}
            {strategy && isLgUp ? undefined : (
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={!changeStyle ? 'column-reverse' : 'row'}
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_detail.realised_p_or_l" />
                </Typography>
                <Typography
                  variant={isMdUp ? 'body1' : 'body2'}
                  color={getColorByNum(total_profit_loss)}
                >
                  {`${getArrowDirByNum(
                    total_profit_loss,
                  )} ${currencySymbol}${formatNumber(
                    total_profit_loss,
                    false,
                    0,
                  )} (${decimalFormat(total_profit_loss_per)}%)`}
                </Typography>
              </Box>
            )}
            {/* {strategy && (
            <> */}
            <FeatureFlag
              features={[FEATURES.IS_CHARGES_AND_PROFIT]}
              fallback={<></>}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={!changeStyle ? 'column-reverse' : 'row'}
                alignItems="center"
                sx={{ mb: 1 }}
              >
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="brokerage_fees" />
                </Typography>
                <Typography
                  variant={changeStyle ? 'body2' : 'body1'}
                  color={getFeesColor()}
                  fontWeight={500}
                >
                  {currencySymbol}
                  {formatNumber(Math.round(total_fees!), false, 0)}
                </Typography>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexDirection={!changeStyle ? 'column-reverse' : 'row'}
                alignItems="center"
                sx={changeStyle ? { mb: 0 } : { mb: 1 }}
              >
                <Typography variant="body3" color="text.secondary">
                  <FormattedMessage id="summary_detail.net_profit" />
                </Typography>
                <Typography
                  variant={changeStyle ? 'body2' : 'body1'}
                  color={getColorByNum(netProfitPer)}
                  fontWeight={500}
                >
                  {currencySymbol}
                  {formatNumber(netProfit!, false, 0)} (
                  {formatNumber(netProfitPer, true)}%)
                </Typography>
              </Box>
            </FeatureFlag>
            {/* </>
          )} */}
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={!changeStyle ? 'column-reverse' : 'row'}
              alignItems="center"
              sx={{ mb: 1 }}
            >
              <Typography variant="body3" color="text.secondary">
                <FormattedMessage id="summary_detail.portfolio" />
              </Typography>
              <Box display="flex" alignItems="center">
                {isMdUp ? (
                  <Typography
                    variant={changeStyle ? 'body2' : 'body1'}
                    color="text.secondary"
                  >
                    {currencySymbol}
                    {formatNumber(initial_capital, true)}
                  </Typography>
                ) : (
                  <Typography
                    variant={changeStyle ? 'body2' : 'body1'}
                    fontWeight={500}
                    color="text.secondary"
                  >
                    {currencySymbol}
                    {formatNumber(initial_capital, true)}
                  </Typography>
                )}
                <ArrowForwardIcon sx={{ fontSize: '12px', mx: 0.5 }} />
                {isMdUp ? (
                  <Typography
                    variant={changeStyle ? 'body2' : 'body1'}
                    color={getColorByNum(netProfit!)}
                  >{`${currencySymbol}${formatNumber(
                    portfolio_value,
                    true,
                  )}`}</Typography>
                ) : (
                  <Typography
                    variant={changeStyle ? 'body2' : 'body1'}
                    fontWeight={500}
                    color={getColorByNum(netProfit!)}
                  >
                    {`${currencySymbol}${formatNumber(portfolio_value, true)}`}
                  </Typography>
                )}
              </Box>
            </Box>
            {/* {!!isMdUp && isSharePortfolio && query && (
              <Box
                sx={
                  changeStyle
                    ? { display: 'flex', justifyContent: 'flex-end', mt: 2 }
                    : {}
                }
              >
                <IconButton onClick={handleMutateClick}>
                  <IosShare />
                </IconButton>
              </Box>
            )} */}
            {started_date && (
              <>
                <Divider sx={{ my: 1 }} />
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems="center"
                >
                  <Typography variant="body3" color="text.secondary">
                    <FormattedMessage id="summary_detail.date" />
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={500}
                    color="text.secondary"
                  >
                    {started_date}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <SocialShareModal
          generatedURL={
            postportflioMutation.isLoading
              ? 'Your URL is being generated...'
              : completeURL
          }
          handleClose={() => setSocialShareModal(false)}
          open={socialShareModal}
        />
      </Box>
      <RealisedPopover
        id={id}
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        realizedLoss={realizedLoss || 0}
        realizedProfit={realizedProfit || 0}
        unrealizedLoss={unrealizedLoss}
        unrealizedProfit={unrealizedProfit}
        currencySymbol={currencySymbol}
      />
    </Box>
  );
};

export default memo(SummaryDetail);
