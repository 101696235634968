import { FieldData, FieldDataConditions } from '../Strategy/fields/types';
import {
  ButtonGroupRenderer,
  CheckFieldRenderer,
  DateRenderer,
  DropdownFieldRenderer,
  InputFieldRenderer,
  RadioButtonsRenderer,
  TimeRenderer,
  SelectWithSingleInputRenderer,
  SelectWithDoubleInputRenderer,
  ToggleButtonGroupRenderer,
} from './Renderers';

type Validation = {
  status: boolean;
  error: string;
};

export type DropdownFieldValue = {
  key: string;
  label: string | number | boolean;
  tooltip?: string;
};

export type ButtonGroupFieldValue = {
  key: string;
  label: string;
  tooltip?: string;
};

export type RadioFieldValue = {
  key: string;
  label: string | number | boolean;
};

export type ControlsType = {
  key: string;
  type:
    | 'dropdown'
    | 'input'
    | 'radio'
    | 'checkbox'
    | 'date'
    | 'time'
    | 'buttongroup'
    | 'togglebuttongroup'
    | 'input_text'
    | 'multi_select';
  values?:
    | Array<DropdownFieldValue>
    | Array<ButtonGroupFieldValue>
    | Array<RadioFieldValue>;
  defaultValue:
    | string
    | number
    | boolean
    | Array<ButtonGroupFieldValue['key']>
    | ButtonGroupFieldValue['key']
    | RadioFieldValue['key'];
  isRequired: boolean;
  enabled: boolean;
  prefix?: string;
  helpText?: string;
  width?: number;
  tooltip?: string;
  closeButton?: boolean;
};

export type ValidateState = { error: string; status: boolean };

type BaseFieldParam = {
  name?: string;
  parentKey: string;
  group: number;
  tooltip?: string;
  validation: Validation;
  width?: number | undefined;
  type?: string;
  myStyle?: Record<
    string | 'small' | 'medium' | 'large' | 'root',
    string | Record<string, string | Record<string, string>>
  >;
  addClass?: string;
  hide?: boolean;
  uniqueKey?: string;
  renderer:
    | typeof DropdownFieldRenderer
    | typeof InputFieldRenderer
    | typeof RadioButtonsRenderer
    | typeof CheckFieldRenderer
    | typeof DateRenderer
    | typeof TimeRenderer
    | typeof ButtonGroupRenderer
    | typeof SelectWithSingleInputRenderer
    | typeof SelectWithDoubleInputRenderer
    | typeof ToggleButtonGroupRenderer;
};

export type DropdownFieldParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type SelectWithSingleInputParam = BaseFieldParam & {
  controls: Array<ControlsType>;
  type: 'selectwithinput';
  isAddButtonActive: boolean;
};

export type SelectWithDoubleInputParam = BaseFieldParam & {
  controls: Array<ControlsType>;
  type: 'selectwithinput';
  isAddButtonActive: boolean;
};

export type ButtonGroupParam = BaseFieldParam & {
  controls: Array<ControlsType>;
  renderingType: 'horizontal' | 'vertical';
};

export type ToogleButtonGroupParam = BaseFieldParam & {
  controls: Array<ControlsType>;
  renderingType: 'horizontal' | 'vertical';
};

export type RadioButtonParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type InputFieldParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type CheckboxFieldParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type DateFieldParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type TimeFieldParam = BaseFieldParam & {
  controls: Array<ControlsType>;
};

export type FieldParamValue =
  | DropdownFieldValue['key']
  | string
  | number
  | boolean
  | string[]
  | FieldData[]
  | ControlsType;

export type FieldParam =
  | DropdownFieldParam
  | ButtonGroupParam
  | InputFieldParam
  | RadioButtonParam
  | CheckboxFieldParam
  | DateFieldParam
  | TimeFieldParam
  | SelectWithSingleInputParam
  | SelectWithDoubleInputParam
  | ToogleButtonGroupParam;

export type OptionLegParam = Record<
  string,
  | string
  | boolean
  | Record<string, FieldDataConditions>
  | Record<string, FieldParamValue | Record<string, FieldParamValue>>
  | OptionLegParam[]
>;

export type OptionLegExecutionParam = {
  order_type: string;
  buffer_type: string;
  limit_buffer: number;
  trigger_buffer: number;
  convert_to_market_after: number;
};
export type OptionSetParam = Record<
  string,
  | string
  | Record<string, FieldDataConditions>
  | Record<string, FieldParamValue | Record<string, FieldParamValue>>
  | OptionLegParam[]
>;

export type OptionStrategyParam = Record<
  string,
  string | Record<string, string> | OptionSetParam[]
>;

export type CopiedStrategyParam = {
  set: OptionSetParam;
  leg: OptionLegParam;
  hedge: OptionLegParam;
};

export type OptionDayWiseParam = Record<
  string,
  string | Record<string, FieldParamValue>
>;
// Record<string, FieldParamValue>;

export const DAY_WISE_STOPLOSS = 'day_wise_sl';
export const DAY_WISE_TARGETPROFIT = 'day_wise_tg';
export const DAY_WISE_TARGETSTOPLOSS = 'day_wise_tsl';
export const LOCK_PROFIT = 'lock_profit';

export type DayWiseKey =
  | typeof DAY_WISE_STOPLOSS
  | typeof DAY_WISE_TARGETPROFIT
  | typeof DAY_WISE_TARGETSTOPLOSS
  | typeof LOCK_PROFIT;

export type ExternalFieldData = {
  enable: boolean;
  source?: string;
  max_signals_entry?: number;
  max_signals_exit?: number;
  entry_webhook_url?: string;
  entry_msg_data?: ExternalFieldMsgType;
  exit_webhook_url?: string;
  exit_msg_data?: ExternalFieldMsgType;
};

export type ExternalFieldMsgType = {
  strategy_id: number;
  signal_type: number;
  set_name: string;
};
