import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import SymbolCard from './SymbolCard';
import MuiButton from 'app/design/uiComponents/MuiButton';
import { useState } from 'react';
import TradeDetailsCard from './TradeDetailsCard';
import useGetTradeOptionsData from 'services/TradeIdeas/useGetTradeOptionsData';
import moment from 'moment';
import { SYMBOL } from '../utils';
import { useGetMediaQueryDown } from 'app/hooks/useGetMediaQuery';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useBreadcrumbManager } from 'app/components/Common/Breadcrumb';
import { PAGES } from 'app/components/Common/Breadcrumb/types';
import { getQueryParams } from 'utils/GenericFunctions';
import { useTheme } from '@mui/material/styles';
import { GetInstrumentsResult } from 'types/Instrument';

type TradeOptionsCardProps = {
  selectedSymbol: string;
  description: string;
  expiry: string;
  instruments: GetInstrumentsResult;
};

export default function TradeOptionsCard({
  selectedSymbol,
  description,
  expiry,
  instruments,
}: TradeOptionsCardProps) {
  const theme = useTheme();
  const {
    CardBackgroundColorValue: cbv,
    ButtonBackgroundColorValue: bbv,
    BorderColorValue: bcv,
  } = theme.component.tradeIdeas;
  const CardBackgroundColorValue = {
    bullish: cbv.bullish,
    bearish: cbv.bearish,
    sideways: cbv.sideways,
    directional: cbv.directional,
  };

  const ButtonBackgroundColorValue = {
    bullish: bbv.bullish,
    bearish: bbv.bearish,
    sideways: bbv.sideways,
    directional: bbv.directional,
  };

  const BorderColorValue = {
    bullish: bcv.bullish,
    bearish: bcv.bearish,
    sideways: bcv.sideways,
    directional: bcv.directional,
  };
  const { symbol, view } = getQueryParams(['symbol', 'view']);
  const [selectedView, setSelectedView] = useState(
    symbol === selectedSymbol ? view : 'bullish',
  );
  const breadcrumb = useBreadcrumbManager();

  const isMdDown = useGetMediaQueryDown('md');

  const { data: trade_options } = useGetTradeOptionsData(
    selectedSymbol,
    selectedView || 'bullish',
  );
  const expiryDayDiff = moment(expiry).diff(
    moment().format('YYYY-MM-DD'),
    'day',
  );

  const handleViewAll = () => {
    breadcrumb.push(
      `/trade-options/trade-table?symbol=${selectedSymbol}&view=${selectedView}`,
      PAGES.TRADE_OPTIONS,
      `/trade-options`,
      'Trade Ideas',
    );
  };
  return (
    <Box
      sx={{
        borderRadius: 2,
        border: '2px solid grey.300',
        boxShadow: 4,
        m: 2,
        py: 2,
        bgcolor: theme.palette.common.white,
      }}
    >
      <Box
        display={'flex'}
        className="p-main-spacing"
        justifyContent={'space-between'}
      >
        <Typography>{`Where do you think ${
          SYMBOL[`${selectedSymbol}`]
        } will go ${
          expiryDayDiff === 0
            ? 'today'
            : expiryDayDiff === 1
            ? 'tomorrow'
            : `in ${expiryDayDiff} days`
        } ?`}</Typography>
        <Box
          sx={{
            display: 'flex',
            p: 0,
            cursor: 'pointer',
            alignItems: 'center',
            color: theme.component.tradeIdeas.mainSelectedCard,
          }}
          onClick={handleViewAll}
        >
          <Typography variant="subtitle2">View All</Typography>
          <KeyboardDoubleArrowRight sx={{ fontSize: '17px' }} />
        </Box>
      </Box>
      <Grid container my={2}>
        <Grid item md={12}>
          <SymbolCard symbol={selectedSymbol} description={description} />
        </Grid>
      </Grid>
      <Box mx={1} display={'flex'} justifyContent={'space-around'}>
        {['Bullish', 'Bearish', 'Sideways', 'Directional'].map(
          (view_type, index) => (
            <Box
              key={index}
              sx={{
                flex: 1,
                p: 2,
                borderRadius: 2,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                background: `${
                  selectedView === view_type.toLowerCase()
                    ? ButtonBackgroundColorValue[selectedView]
                    : ''
                }`,
              }}
            >
              <MuiButton
                variant="outlined"
                fullWidth
                color="inherit"
                sx={{
                  border:
                    selectedView === view_type.toLowerCase()
                      ? `1px solid ${BorderColorValue[selectedView]}`
                      : '',
                  // bgcolor: 'red',
                }}
                size={isMdDown ? 'small' : 'medium'}
                onClick={() => {
                  view === view_type.toLowerCase()
                    ? setSelectedView('')
                    : setSelectedView(view_type.toLowerCase());
                }}
              >
                {view_type}
              </MuiButton>
            </Box>
          ),
        )}
      </Box>
      {selectedView && (
        <Box
          sx={{
            // bgcolor: '#e5effb',
            background: `${CardBackgroundColorValue[selectedView]}`,
            p: 2,
            mx: 1,
            display: 'flex',
            flex: 1,
            flexDirection: isMdDown ? 'column' : 'row',
            justifyContent: 'space-between',
            borderRadius:
              selectedView === 'bullish'
                ? '0px 8px 8px 8px'
                : selectedView === 'directional'
                ? '8px 0px 8px 8px'
                : 1,
          }}
        >
          {trade_options && trade_options.trades ? (
            Object.keys(trade_options.trades).map((trade, index) => {
              return (
                <>
                  {trade_options.trades[trade] && (
                    <TradeDetailsCard
                      key={index}
                      symbol={selectedSymbol}
                      trade_type={trade}
                      trade_details={trade_options.trades[trade]}
                      view={selectedView}
                      expiry={expiry}
                      instruments={instruments}
                    />
                  )}
                </>
              );
            })
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </Box>
  );
}
