import { Box, Typography } from '@mui/material';
import { Order, PositionMetaDataIgnoreKeys } from 'types/ApiServicesTypes';
import { formatNumber, getDate } from 'utils/GenericFunctions';
import { ItemSeparator } from '../../EntityItem';
import { orderStatus, orderTypes, tags } from './utils';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { ChipColor } from 'types/Generic';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { FEATURES } from 'types/Feature';

const InfoListOrder = ({ data, source }: { data: Order; source?: string }) => {
  // const netProfit = data ? data.profit_loss - data.totalfees : 0;
  const isPositionMetaData = useIsFeatureFlag([FEATURES.IS_POSITION_METADATA]);
  const isShowDecimalprecision = useIsFeatureFlag([
    FEATURES.IS_SHOW_DECIMAL_PRECISION,
  ]);
  const metaData = Object.entries(
    data.metadata?.OrderMetadata ? data.metadata.OrderMetadata : {},
  ).filter(
    ([key]) =>
      !(key in PositionMetaDataIgnoreKeys || key.startsWith('private_')),
  );

  return (
    <Box className="p-main-spacing" sx={{ py: 2 }}>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.qty" />
        </Typography>
        <Typography
          variant="body3"
          fontWeight={500}
          color={data.direction === '0' ? 'success.main' : 'error.main'}
        >
          {Math.abs(data.quantity).toString()}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.status" />
        </Typography>
        <Box>
          <MuiChip
            icon={<>{orderStatus[data.status].icon}</>}
            label={<>{orderStatus[data.status].value}</>}
            size="xsmallicon"
            color={orderStatus[data.status].chipColor as ChipColor}
          />
        </Box>
      </Box>
      {(data.type === '1' || data.type === '3') && (
        <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.limit_price" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {data.currency_symbol}
            {formatNumber(
              data.limit_price,
              undefined,
              isShowDecimalprecision ? 2 : 10,
              isShowDecimalprecision,
            )}
          </Typography>
        </Box>
      )}
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.order_price" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.currency_symbol}
          {formatNumber(
            data.price,
            undefined,
            isShowDecimalprecision ? 2 : 10,
            isShowDecimalprecision,
          )}
        </Typography>
      </Box>
      {data.type === '3' && (
        <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.stop_price" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {data.currency_symbol}
            {formatNumber(
              data.stop_price,
              undefined,
              isShowDecimalprecision ? 2 : 10,
              isShowDecimalprecision,
            )}
          </Typography>
        </Box>
      )}
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.order_value" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {data.currency_symbol}
          {formatNumber(
            data.value,
            undefined,
            isShowDecimalprecision ? 2 : 10,
            isShowDecimalprecision,
          )}
        </Typography>
      </Box>
      <ItemSeparator variant="1px" />
      <Box display={'flex'} justifyContent={'space-between'} sx={{ py: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.order_type" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {orderTypes[data.type]}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.order_date" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {getDate(data.order_date, true)}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.last_fill" />
        </Typography>
        <Typography variant="body3" fontWeight={500}>
          {getDate(data.last_fill_time, true)}
        </Typography>
      </Box>
      {source === 'Portfolio' && (
        <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 2 }}>
          <Typography variant="body3" color="text.secondary">
            <FormattedMessage id="summary_labels.broker_order_id" />
          </Typography>
          <Typography variant="body3" fontWeight={500}>
            {data.broker_order_id ? data.broker_order_id : '-'}
          </Typography>
        </Box>
      )}
      <ItemSeparator variant="1px" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2 }}
      >
        <Typography variant="body3" color="text.secondary">
          <FormattedMessage id="summary_labels.tag" />
        </Typography>
        <MuiChip
          label={tags[data.tag] ? (tags[data.tag] as string) : data.tag}
          size="xsmall"
          color="greylight"
        />
      </Box>
      {isPositionMetaData && metaData.length > 0 && (
        <>
          <ItemSeparator variant="1px" />
          <Box mt={2}>
            {metaData.map(([key, value]) => (
              <Box
                key={key}
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                sx={{ pb: 2 }}
                alignItems={'baseline'}
              >
                <Typography
                  variant="body3"
                  color="text.secondary"
                  flexShrink={0}
                >
                  {key}
                </Typography>
                <Typography variant="body3" fontWeight={500}>
                  {value}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {data.message && (
        <>
          <ItemSeparator variant="1px" />
          <Box display="flex" sx={{ py: 2 }} gap={2}>
            <Typography
              variant="body3"
              color="text.secondary"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="summary_labels.message" />
            </Typography>
            <Typography variant="body3" fontWeight={500}>
              {data.message}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InfoListOrder;
