import PageHeader from 'app/design/speedBot/PageHeader';
import { Box, IconButton, Typography } from '@mui/material';
import { formatDateString, isInfinite } from 'utils/GenericFunctions';
import MuiChip from 'app/design/uiComponents/MuiChip';
import { TradesType } from 'app/components/TradeIdeas/types';
import { useGetMediaQueryUp } from 'app/hooks/useGetMediaQuery';
import { useState } from 'react';
import TradePayoffChart from './TradePayoffChart';
import { dataPointForPositions } from './utils';
import MuiModal from 'app/design/uiComponents/MuiModal';
import moment from 'moment';
import MuiButton from 'app/design/uiComponents/MuiButton';
import FormattedMessage from 'app/design/uiComponents/FormattedMessage';

type TradeRowDetailsModalProps = {
  open: boolean;
  handleClose: () => void;
  handleExecute: (boolean) => void;
  tradeData: TradesType | null;
  // instruments: Instrument[];
};

const TradeRowDetailsModal = ({
  open,
  handleClose,
  handleExecute,
  tradeData,
}: // instruments,
TradeRowDetailsModalProps) => {
  const isMdUp = useGetMediaQueryUp('md');
  const [lotSize, setLotSize] = useState(1);

  const dataPoints = dataPointForPositions(tradeData?.positions, lotSize);

  return (
    <MuiModal
      maxWidth="lg"
      open={open}
      handleClose={handleClose}
      body={
        <>
          <PageHeader
            variant="withSubtitle"
            subtitle={tradeData ? tradeData.strategy_type : ''}
            showMdUp={true}
            title={tradeData ? tradeData.symbol : ''}
            {...(isMdUp && { hideBackButton: true })}
          />
          <Box className="p-main-spacing">
            <Box width={'100%'} height={'460px'}>
              <TradePayoffChart dataPoints={dataPoints} />
            </Box>
            <Box sx={{ py: 2 }}>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Candle Time
                </Typography>
                {tradeData && (
                  <Typography variant="body2" fontWeight={500}>
                    {formatDateString(
                      tradeData.candle_time,
                      'DD MMM YYYY HH:mm',
                    )}
                  </Typography>
                )}
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Max Profit
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={'success.main'}
                >
                  {tradeData &&
                    (isInfinite(tradeData.payoff.max_gain * lotSize)
                      ? 'Unlimited'
                      : tradeData.payoff.max_gain * lotSize)}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Max Loss
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color={'error.main'}
                >
                  {tradeData &&
                    (isInfinite(tradeData.payoff.max_loss * lotSize)
                      ? '-Unlimited'
                      : tradeData.payoff.max_loss * lotSize)}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Breakeven
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {tradeData &&
                    tradeData.payoff.breakevens.map(
                      (breakeven, index) =>
                        `${breakeven}${
                          tradeData.payoff.breakevens.length - 1 !== index
                            ? ','
                            : ''
                        }`,
                    )}
                </Typography>
              </Box>
              {tradeData &&
                !(
                  isInfinite(tradeData.payoff.max_gain * lotSize) ||
                  isInfinite(tradeData.payoff.max_loss * lotSize)
                ) && (
                  <>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      py={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        Alpha
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {tradeData.payoff.alpha}%
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      py={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        EV
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {tradeData.payoff.ev}
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      py={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        POP
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {tradeData.payoff.pop}%
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      py={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        PML
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {tradeData.payoff.pml}%
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      py={1}
                    >
                      <Typography variant="body2" color="text.secondary">
                        PMP
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {tradeData.payoff.pmp}%
                      </Typography>
                    </Box>
                  </>
                )}
              <Box display={'flex'} justifyContent={'space-between'} py={1}>
                <Typography variant="body2" color="text.secondary">
                  Quantity
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton
                      sx={{
                        borderRadius: 1,
                        border: '1px solid grey',
                        mx: 2,
                        py: '3px',
                        px: '8px',
                      }}
                      onClick={() => {
                        setLotSize(lotSize === 1 ? 1 : lotSize - 1);
                      }}
                    >
                      <Typography>-</Typography>
                    </IconButton>
                    <Typography sx={{ alignContent: 'center' }}>
                      {lotSize}
                    </Typography>
                    <IconButton
                      sx={{
                        borderRadius: 1,
                        border: '1px solid grey',
                        mx: 2,
                        py: '3px',
                        px: '8px',
                      }}
                      onClick={() => {
                        setLotSize(lotSize + 1);
                      }}
                    >
                      <Typography>+</Typography>
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: 'grey.100',
                borderRadius: 2,
                p: 1,
              }}
            >
              <Typography variant="body2">Trade Detail</Typography>
              {tradeData &&
                tradeData.positions.map((position, index) => (
                  <Box
                    key={index}
                    display={'flex'}
                    justifyContent={'space-between'}
                    py={1}
                  >
                    <Typography variant="body2">
                      {moment(String(tradeData.expiry))
                        .format('DDMMM')
                        .toLocaleUpperCase()}{' '}
                      {position.strike} {position.contract_type.toUpperCase()}
                    </Typography>
                    <MuiChip
                      color={`${
                        position.direction === 'long'
                          ? 'successlight'
                          : 'errorlight'
                      }`}
                      variant="filled"
                      label={position.direction === 'long' ? 'Buy' : 'Sell'}
                      sx={{ ml: 1 }}
                      size="small"
                    />
                  </Box>
                ))}
            </Box>
            <MuiButton
              size="medium"
              variant="contained"
              color="info"
              fullWidth={true}
              onClick={() => {
                handleExecute(true);
              }}
              sx={{ my: 2 }}
            >
              <FormattedMessage id="trade_ideas.execute" />
            </MuiButton>
          </Box>
        </>
      }
    />
  );
};

export default TradeRowDetailsModal;
