import { useState } from 'react';
import {
  OutlinedInput,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from '@mui/material';
import { ControlsType, DropdownFieldValue } from '../types';

interface Props {
  options: DropdownFieldValue[] | undefined;
  control: ControlsType;
  onchange: (value: string[], control: ControlsType) => void;
  selected: string[];
  // param: Param;
  // selected: Record<string, FieldParamValue>;
  // onChange: (key: string, value: Record<string, FieldParamValue>) => void;
  // sendValidation: (key: string, isValid: boolean) => void;
  // isReadOnly: boolean;
  // optionLeg?: OptionLegParam;
}
export const MultiSelection = (props: Props) => {
  const [selectedNames, setSelectedNames] = useState<string[]>(
    (props.selected || []).filter(name =>
      props.options?.some(option => option.key === name),
    ),
  );
  const changeValue = (value: string[]) => {
    setSelectedNames(value);
    props.onchange(value, props.control);
  };

  return (
    <FormControl
      sx={
        props.control?.width
          ? {
              minWidth: props.control?.width,
            }
          : {}
      }
    >
      <Select
        multiple
        value={selectedNames}
        onChange={e => changeValue(e.target.value as string[])}
        input={<OutlinedInput label="Multiple Select" />}
        renderValue={selected => (
          <Stack gap={1} direction="row">
            {selected.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Stack>
        )}
      >
        {props.options?.map(name => (
          <MenuItem key={name.key} value={name.key} className="select-option">
            {name.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
