// Service
import { createBotOrderTableRows } from './utils';
import { Box } from '@mui/material';
import CommonTable from 'app/components/Common/CommonTable';
import { useIsFeatureFlag } from 'app/components/Common/FeatureFlag';
import { Order } from 'types/ApiServicesTypes';
import { FEATURES } from 'types/Feature';
// import BotOrderRowDetail from './BotOrderRowDetail';

// const DetailsRowRenderer = ({
//   rowData,
//   handleClose,
// }: {
//   rowData: Order;
//   handleClose: () => void;
// }) => {
//   return <BotOrderRowDetail order={rowData} handleClose={handleClose} />;
// };

const BotOrdersTable = ({
  orders,
  handleOrderRowClick,
}: {
  orders: Order[] | undefined;
  handleOrderRowClick?: (orderData: Order) => void;
}) => {
  const isShowDecimalprecision = useIsFeatureFlag([
    FEATURES.IS_SHOW_DECIMAL_PRECISION,
  ]);
  const { columns, rows } = createBotOrderTableRows(
    orders,
    handleOrderRowClick,
    isShowDecimalprecision
  );

  return (
    <>
      <Box sx={{ px: 3, mx: 1 }}>
        <CommonTable
          whichTable="orders"
          rows={rows}
          columns={columns}
          handleChartRowClick={handleOrderRowClick}
          // DetailsRowRenderer={DetailsRowRenderer}
        />
      </Box>
    </>
  );
};

export default BotOrdersTable;
